import { FC } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { PartReadPublic } from '../../store/clientApi'
import { invalidateDetailsItem } from '../../store/slices/detailsDrawerSlice'
import { invalidate, selectNumChecked } from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { ActionBar } from '../ActionBar/ActionBar'
import {
  GenericPartButtonGroup,
  PartButtonGroup,
  PartButtonGroupType,
} from './buttons'

interface InboxActionBarProps {
  table: string
  type: PartButtonGroupType
  isInspectorPanel?: boolean
}

interface GenericPartsActionBarProps {
  type: PartButtonGroupType
  isInspectorPanel?: boolean
  invalidateTable: () => void
  selectParts: (state: RootState) => PartReadPublic[]
  selectNumChecked: (state: RootState) => number
}

export const PartActionBar: FC<InboxActionBarProps> = ({
  table,
  type,
  isInspectorPanel = false,
}) => {
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]

  const invalidateTable = () => {
    store.dispatch(invalidate(table))
    store.dispatch(invalidateDetailsItem({ page: basePath, tab: selectedTab }))
  }

  return (
    <ActionBar
      handleCloseActionBar={invalidateTable}
      selector={(state: RootState) => selectNumChecked(state, table)}
    >
      <PartButtonGroup
        table={table}
        isInspectorPanel={isInspectorPanel}
        type={type}
      />
    </ActionBar>
  )
}

export const GenericPartActionBar: FC<GenericPartsActionBarProps> = ({
  type,
  invalidateTable,
  selectParts,
  selectNumChecked,
  isInspectorPanel = false,
}) => {
  return (
    <ActionBar
      handleCloseActionBar={invalidateTable}
      selector={selectNumChecked}
    >
      <GenericPartButtonGroup
        invalidateTable={invalidateTable}
        selectParts={selectParts}
        isInspectorPanel={isInspectorPanel}
        type={type}
      />
    </ActionBar>
  )
}
