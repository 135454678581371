import { PartStatus } from '../constants/partStatus'
import { ModelDataObject, ModelStateData } from '../typed/ModelStateData'

const DB_NAME = 'modelsCacheDB'
const DB_VERSION = 3
const MODELS_STORE_NAME = 'models'
const THUMBNAILS_STORE_NAME = 'thumbnails'
const INVALIDATION_TIMEOUT = 5 * 1000 * 60 * 60 * 24 // 5 days in milliseconds

const openDatabase = async () => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION)

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result
      if (!db.objectStoreNames.contains(MODELS_STORE_NAME)) {
        db.createObjectStore(MODELS_STORE_NAME)
      }
      if (!db.objectStoreNames.contains(THUMBNAILS_STORE_NAME)) {
        db.createObjectStore(THUMBNAILS_STORE_NAME)
      }
    }

    request.onsuccess = (event) => {
      resolve((event.target as IDBOpenDBRequest).result)
    }

    request.onerror = (event) => {
      reject((event.target as IDBOpenDBRequest).error)
    }
  })
}

const partRequiresInvalidation = (
  currentStatus: number,
  cachedStatus: number,
): boolean => {
  // Check if the model has changed - i.e., has be re-oreinted
  // where the status has changed from uploadPending (0), uploaded (1), or processing (2)
  if (
    (cachedStatus === PartStatus['uploadPending'] ||
      cachedStatus === PartStatus['uploaded'] ||
      cachedStatus === PartStatus['processing']) &&
    currentStatus !== PartStatus['uploadPending'] &&
    currentStatus !== PartStatus['uploaded'] &&
    currentStatus !== PartStatus['processing']
  ) {
    return true
  }
  return false
}

export interface modelObjectIndexedDB {
  dataStatus: number // PartReadPublic or JobReadPublic status
  data: ModelStateData // mesh data
  timestamp: number // invalidation timestamp
}

export interface thumbnailObjectIndexedDB {
  dataStatus: number // PartReadPublic status
  data: Blob // image data
  timestamp: number // invalidation timestamp
}

export const saveDataToIndexedDB = async (
  id: string,
  model?: modelObjectIndexedDB,
  thumbnail?: thumbnailObjectIndexedDB,
) => {
  const storeData = [
    { storeName: MODELS_STORE_NAME, data: model },
    { storeName: THUMBNAILS_STORE_NAME, data: thumbnail },
  ].filter((entry) => entry.data !== undefined)

  if (storeData.length === 0) return

  const db = await openDatabase()
  const transaction = db.transaction(
    storeData.map((entry) => entry.storeName),
    'readwrite',
  )

  storeData.forEach(({ storeName, data }) => {
    transaction.objectStore(storeName).put(data, id)
  })

  return new Promise<void>((resolve, reject) => {
    transaction.oncomplete = () => resolve()
    transaction.onerror = (event) =>
      reject((event.target as IDBTransaction).error)
  })
}

/**
 *
 * @param modelId The key of the model to retrieve
 * @returns Boolean: True if the model exists in the indexedDB store, False otherwise
 */
export const checkModelInIndexedDB = async (
  modelId: string,
  modelType: 'PART' | 'JOB',
): Promise<boolean> => {
  const db = await openDatabase()
  const transaction = db.transaction(MODELS_STORE_NAME, 'readwrite')
  const store = transaction.objectStore(MODELS_STORE_NAME)
  const request = store.count(`${modelType}-${modelId}`)

  return new Promise((resolve, reject) => {
    request.onsuccess = function () {
      resolve(request.result !== 0)
    }
    request.onerror = function () {
      resolve(false)
    }
  })
}

export const getModelFromIndexedDB = async (
  id: string,
  dataType: 'PART' | 'JOB',
  dataStatus?: number,
) => {
  const db = await openDatabase()
  const transaction = db.transaction(MODELS_STORE_NAME, 'readwrite')
  const store = transaction.objectStore(MODELS_STORE_NAME)
  const key = `${dataType}-${id}`
  const request = store.get(key)

  return new Promise<ModelDataObject | null>((resolve, reject) => {
    request.onsuccess = (event) => {
      // Check if timestamp is beyond timeout for invalidation
      const storeItem = request.result as modelObjectIndexedDB

      if (!storeItem || !storeItem.data || !storeItem.timestamp) {
        resolve(null)
        return
      }

      if (Math.abs(Date.now() - storeItem.timestamp) > INVALIDATION_TIMEOUT) {
        store.delete(key)
        resolve(null)
        return
      }

      if (
        dataStatus !== undefined &&
        storeItem.data.type === 'stl' &&
        partRequiresInvalidation(dataStatus, storeItem.dataStatus)
      ) {
        resolve(null)
        return
      }

      resolve(storeItem.data)
    }
    request.onerror = (event) => reject(request.error)
  })
}

export const clearThumbnailItemFromIndexedDB = async (id: string) => {
  const db = await openDatabase()
  const transaction = db.transaction(THUMBNAILS_STORE_NAME, 'readwrite')
  const store = transaction.objectStore(THUMBNAILS_STORE_NAME)
  const request = store.delete(id)

  return new Promise<undefined>((resolve, reject) => {
    request.onsuccess = (event) => {
      resolve(undefined)
    }
    request.onerror = (event) => {
      reject(undefined)
    }
  })
}

export const getThumbnailFromIndexedDB = async (id: string) => {
  const db = await openDatabase()
  const transaction = db.transaction(THUMBNAILS_STORE_NAME, 'readwrite')
  const store = transaction.objectStore(THUMBNAILS_STORE_NAME)
  const request = store.get(id)

  return new Promise<Blob | null>((resolve, reject) => {
    request.onsuccess = (event) => {
      // Check if timestamp is beyond timeout for invalidation
      const storeItem = request.result as thumbnailObjectIndexedDB
      if (!storeItem || !storeItem.data || !storeItem.timestamp) {
        resolve(null)
        return
      }

      if (Math.abs(Date.now() - storeItem.timestamp) > INVALIDATION_TIMEOUT) {
        store.delete(id)
        resolve(null)
        return
      }

      resolve(storeItem.data)
    }
    request.onerror = (event) => reject(request.error)
  })
}
