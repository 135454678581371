import { useAuth0 } from '@auth0/auth0-react'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import {
  Box,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { AppList } from '../../components/AppList/AppList'
import { AppsGrid } from '../../components/AppsGrid/AppsGrid'
import { Button } from '../../components/Button/Button'
import { Dashboard, DashboardLink } from '../../components/Dashboard/Dashboard'
import { DialogBox } from '../../components/DialogBox'
import {
  AddSvg,
  AppsSvg,
  IntegrationsSvg,
  RefreshSvg,
  SubtractSvg,
  TemplatesSvg,
  UsersSvg,
} from '../../components/Icon/Icon'
import { Tab, TabList } from '../../components/Tabs/Tabs'
import {
  Templates,
  TemplatesHeaderActions,
} from '../../components/Templates/Templates'
import { UserCreate } from '../../components/UserCreate'
import {
  USER_TABLE_STATE_NAME,
  UsersList,
} from '../../components/UsersList/UsersList'
import { Tabs } from '../../constants/tabs'
import {
  ensureError,
  isSubSet,
  localizeError,
  UserManagementPermissions,
} from '../../helpers'
import { useOrg } from '../../hooks/org'
import { usePageTabs } from '../../hooks/tab'
import {
  clientApi,
  REFETCH_TIMEOUT,
  useDeleteUserApiV1UsersUserIdDeleteMutation,
  UserReadDto,
  UsersPaginatedListRead,
} from '../../store/clientApi'
import {
  ensureTableExists,
  invalidate,
  selectIsChecked,
  selectRtkData,
  selectTableChecked,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'

const invalidateTable = () => store.dispatch(invalidate(USER_TABLE_STATE_NAME))

const refreshUsers = () => {
  store.dispatch(clientApi.util.invalidateTags(['users']))
  store.dispatch(clientApi.util.invalidateTags(['user-role']))
  store.dispatch(clientApi.util.invalidateTags(['users-bfp']))
  invalidateTable()
}

const UsersListActions = () => {
  const { t } = useTranslation('common')

  const theme = useTheme()
  const sm_down = useMediaQuery(theme.breakpoints.down('sm'))
  const md_down = useMediaQuery(theme.breakpoints.down('md'))

  const { enableRemoveUserButton, enableAddUserButton } = useFlags()
  const { org } = useOrg()

  const [addUsersOpen, setAddUsersOpen] = useState(false)
  const [removeOpen, setRemoveOpen] = useState<boolean>(false)
  const [removeArgs, setRemoveArgs] = useState<{ [id: string]: boolean }>({})
  useEffect(() => {
    store.dispatch(ensureTableExists(USER_TABLE_STATE_NAME))
  })
  const canRemove = useSelector((state: RootState) =>
    selectIsChecked(state, USER_TABLE_STATE_NAME),
  )

  const [deleteUser] = useDeleteUserApiV1UsersUserIdDeleteMutation()

  const [checkedCount, setCheckedCount] = useState(0)

  const checked = useSelector((state: RootState) =>
    selectTableChecked(state, USER_TABLE_STATE_NAME),
  )

  const selectUserIds = useMemo(() => {
    const emptyArray: UserReadDto[] = []

    return createSelector(
      [(res?: UsersPaginatedListRead) => res?.content ?? emptyArray],
      (content) =>
        content
          .map((user) => user?.user_id)
          .filter((userId) => userId != null) as string[],
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [])

  const data = useSelector((state: RootState) =>
    selectUserIds(
      selectRtkData(
        state,
        USER_TABLE_STATE_NAME,
        clientApi.endpoints.searchUsersApiV1UsersSearchGet.select,
      )?.data,
    ),
  )

  const remove = () => {
    const newCount = Object.values(checked).filter((value) => value).length
    if (newCount === 0) return
    setRemoveArgs(checked)
    setRemoveOpen(true)
    setCheckedCount(newCount)
    invalidateTable()
  }

  const deleteAccounts = async (
    approve: boolean,
    args: { [id: string]: boolean },
  ) => {
    if (approve) {
      await Promise.all(
        data.map(async (userId): Promise<void> => {
          if (args[userId]) {
            try {
              await deleteUser({ userId: encodeURI(userId) }).unwrap()
            } catch (err) {
              const error = ensureError(err)
              toast.error(localizeError(t, error))
            }
          }
        }),
      )
      setTimeout(() => refreshUsers(), REFETCH_TIMEOUT) // sometimes the auth0 database is delayed in updating data so we need to refetch after a delay to ensure data is up to date
    }
  }

  return sm_down ? (
    <>
      <IconButton id="add-user" onClick={() => setAddUsersOpen(true)}>
        <AddSvg color="inherit" />
      </IconButton>
      <IconButton id="refresh-users" onClick={() => refreshUsers()}>
        <RefreshSvg color="inherit" />
      </IconButton>
    </>
  ) : (
    <>
      <Button
        id="remove-users"
        startIcon={<SubtractSvg color="inherit" />}
        color="secondary"
        sx={{
          visibility: canRemove ? 'visible' : 'hidden',
        }}
        size={md_down ? 'small' : 'medium'}
        onClick={remove}
        disabled={!enableRemoveUserButton}
      >
        {t('users:button.remove')}
      </Button>
      <Button
        id="add-user"
        startIcon={<AddSvg color="inherit" />}
        color="secondary"
        size={md_down ? 'small' : 'medium'}
        onClick={() => setAddUsersOpen(true)}
        disabled={!enableAddUserButton}
      >
        {md_down ? t('common:button.add') : t('users:button.add')}
      </Button>
      <Button
        id="refresh-users"
        startIcon={<RefreshSvg color="inherit" />}
        color="secondary"
        size={md_down ? 'small' : 'medium'}
        onClick={() => refreshUsers()}
      >
        {t('button.refresh')}
      </Button>
      <DialogBox
        args={removeArgs}
        open={removeOpen}
        setOpen={setRemoveOpen}
        title={t('users:title.remove', {
          count: checkedCount,
        })}
        message={
          /*
        t('users:message.confirmDelAccounts_one')
        t('users:message.confirmDelAccounts_other')
        t('users:message.confirmDelAccounts_org_one')
        t('users:message.confirmDelAccounts_org_other')
        */
          t('users:message.confirmDelAccounts', {
            count: checkedCount,
            ...(org?.name && { context: 'org', org: org?.name }),
          })
        }
        onClose={deleteAccounts}
        confirmColor="error"
        confirmText={t('users:button.remove', {
          count: checkedCount,
        })}
        declineText={t('common:button.cancel')}
        sx={{ '.MuiPaper-root': { width: '100%', margin: '16px' } }}
      />
      <DialogBox
        title={t('users:title.invite')}
        args={undefined}
        message={
          <UserCreate
            onCreate={() => {
              setAddUsersOpen(false)
              invalidateTable()
            }}
          />
        }
        open={addUsersOpen}
        setOpen={setAddUsersOpen}
        onClose={() => {
          return
        }}
        sx={{ '.MuiPaper-root': { width: '100%', margin: '16px' } }}
        hideConfirm={true}
      />
    </>
  )
}
interface ManageProps {
  primaryLinks: DashboardLink[]
  footerLinks: DashboardLink[]
}
const tabValues = [
  Tabs.MANAGE.USERS,
  Tabs.MANAGE.APPS,
  Tabs.MANAGE.CONNECTED_APPS,
  Tabs.MANAGE.TEMPLATES,
]

const Manage: FC<ManageProps> = (props) => {
  const { releaseAppsPages } = useFlags()
  const { t } = useTranslation('common')
  const UNAVAILABLE = t('common:loading.unavailable')

  const [userManagement, setUserManagement] = useState<boolean>(false)

  const { tab, changeTab } = usePageTabs('manage', tabValues)
  const onChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    changeTab(newValue)
  }

  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0()

  useEffect(() => {
    const decodeToken = async () => {
      const accessToken = await getAccessTokenSilently()

      const decoded = jwtDecode<JwtPayload & { permissions: string[] }>(
        accessToken,
      )

      if (decoded?.permissions) {
        setUserManagement(
          isSubSet(decoded.permissions, UserManagementPermissions),
        )
      }
    }

    decodeToken()
  }, [getAccessTokenSilently, setUserManagement, getIdTokenClaims])

  return (
    <Dashboard
      primaryLinks={props.primaryLinks}
      footerLinks={props.footerLinks}
    >
      <Box
        component="div"
        sx={{
          bgcolor: (theme: Theme) => theme.surface.low,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          padding: '1em',
          borderRadius: '1em',
        }}
      >
        <Typography variant={'h1'} sx={{ marginBottom: '0.5em' }}>
          {t('common:title.manage')}
        </Typography>
        <TabContext value={tab}>
          <TabList
            onChange={onChangeTab}
            aria-label={t('label.settingsTab')}
            sx={{ width: '100%' }}
            buttons={
              tab === tabValues[0] ? (
                <UsersListActions />
              ) : tab === tabValues[3] ? (
                <TemplatesHeaderActions />
              ) : undefined
            }
          >
            <Tab
              selectedTab={tab}
              label={t('users:label.users')}
              value={tabValues[0]}
              icon={<UsersSvg color="inherit" />}
            />
            <Tab
              selectedTab={tab}
              label={t('apps:title.templates')}
              value={tabValues[3]}
              sx={{
                display: releaseAppsPages ? 'flex' : 'none',
              }}
              icon={<TemplatesSvg color="inherit" />}
            />
            <Tab
              selectedTab={tab}
              label={t('apps:title.apps')}
              value={tabValues[1]}
              sx={{
                display: releaseAppsPages ? 'flex' : 'none',
              }}
              icon={<AppsSvg color="inherit" />}
            />
            <Tab
              selectedTab={tab}
              label={t('apps:title.connectedApps')}
              value={tabValues[2]}
              sx={{
                display: releaseAppsPages ? 'flex' : 'none',
              }}
              icon={<IntegrationsSvg color="inherit" />}
            />
          </TabList>
          <Box
            component="div"
            sx={{
              padding: '0.4em',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <TabPanel
              sx={{
                padding: '0px',
                height: '100%',
                overflow: 'hidden',
              }}
              value={tabValues[0]}
            >
              {userManagement ? (
                <UsersList />
              ) : (
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '1em',
                  }}
                >
                  {UNAVAILABLE}
                </Box>
              )}
            </TabPanel>
            <TabPanel
              sx={{ padding: '0px', height: '100%' }}
              value={tabValues[3]}
            >
              <Templates />
            </TabPanel>
            <TabPanel
              sx={{ padding: '0px', height: '100%' }}
              value={tabValues[1]}
            >
              <AppsGrid />
            </TabPanel>
            <TabPanel
              sx={{ padding: '0px', height: '100%' }}
              value={tabValues[2]}
            >
              <AppList />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Dashboard>
  )
}

export { Manage }
