import { CircularProgress, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgressStatus } from '../../store/clientApi'
import { partProgressStatusToTranslatedKey } from '../../utils/partProgressStatusToTranslatedKey'
import {
  CompleteSvg,
  ErrorOutlineSvg,
  OfflineReworkSvg,
  UnknownSvg,
} from '../Icon/Icon'
import classes from './PartStatus.module.css'

interface PartProgressStatusProps {
  status: ProgressStatus
}

export const PartProgressStatusText: FC<PartProgressStatusProps> = ({
  status,
}) => {
  const { t } = useTranslation('parts')
  return (
    <>
      {t(
        /*
            t('status.partIdentification.partIdentification')
            t('status.partIdentification.identifyingPartType')
            t('status.partIdentification.confirmPartType')
            t('status.partIdentification.partTypeConfirmed')
            t('status.acceptPartForPrinting.acceptPartForPrinting')
            t('status.acceptPartForPrinting.partAccepted')
            t('status.acceptPartForPrinting.rejected')
            t('status.orientatePart.orientatingPart')
            t('status.orientatePart.orientationFailed')
            t('status.orientatePart.partOrientated')
            t('status.nestPrintJob.nestPrintJob')
            t('status.nestPrintJob.partQueued')
            t('status.nestPrintJob.nestingPrintJob')
            t('status.nestPrintJob.nestingFailed')
            t('status.nestPrintJob.addedToPrintJob')
            t('status.approvePrintJob.approvePrintJob')
            t('status.approvePrintJob.printJobPendingApproval')
            t('status.approvePrintJob.printJobApproved')
            t('status.sendToPrinter.sendToPrinter')
            t('status.sendToPrinter.printJobDownloaded')
            t('status.print.print')
            t('status.print.printing')
            t('status.print.printed')
            t('status.print.printFailed')
            t('status.rejected')
          */
        partProgressStatusToTranslatedKey(status),
      )}
    </>
  )
}

export const PartProgressStatus: FC<PartProgressStatusProps> = ({ status }) => {
  const theme = useTheme()

  const statusIcons = {
    processing: <CircularProgress color="warning" size={14} />,
    pendingApproval: (
      <UnknownSvg
        width="1.3em"
        height="1.3em"
        color={theme.palette.warning.main}
      />
    ),
    rejected: (
      <ErrorOutlineSvg
        width="1.3em"
        height="1.3em"
        color={theme.palette.error.main}
      />
    ),
    success: (
      <CompleteSvg
        width="1.3em"
        height="1.3em"
        color={theme.palette.success.main}
      />
    ),
    offline: (
      <OfflineReworkSvg
        width="1.3em"
        height="1.3em"
        color={theme.palette.primary.main}
      />
    ),
    unknown: (
      <UnknownSvg
        width="1.3em"
        height="1.3em"
        color={theme.palette.secondary.dark}
      />
    ),
  }

  const getPartStatusIndicator = () => {
    switch (status) {
      case 0:
      case 1:
      case 6:
      case 8:
      case 9:
      case 12:
      case 14:
      case 16:
        return statusIcons.processing
      case 2:
      case 3:
      case 11:
        return statusIcons.pendingApproval
      case 4:
      case 17:
        return statusIcons.success
      case 19:
        return statusIcons.offline
      case 5:
      case 7:
      case 10:
      case 13:
      case 15:
      case 18:
        return statusIcons.rejected
      default:
        return statusIcons.unknown
    }
  }

  return (
    <div className={classes.partStatusContainer}>
      <div className={classes.iconContainer}>{getPartStatusIndicator()}</div>
      <Typography>
        <PartProgressStatusText status={status} />
      </Typography>
    </div>
  )
}
