import { Grid } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { FC, useState } from 'react'
import { DoubleSide } from 'three'
import { DeviceBuildExtentsRead } from '../../store/clientApi'

interface BuildPlatformGridProps {
  buildExtents: DeviceBuildExtentsRead
}

export const BuildPlatformGrid: FC<BuildPlatformGridProps> = ({
  buildExtents,
}) => {
  const { camera, invalidate } = useThree()
  const [cameraYPosition, setCameraYPosition] = useState(camera.position.y)

  useFrame(() => {
    if (camera.position.y !== cameraYPosition) {
      setCameraYPosition(camera.position.y)
      invalidate() // Force re-render when camera position changes
    }
  })

  return (
    <Grid
      position={[0, 0.01, 0.01]}
      args={[buildExtents.x, buildExtents.y]}
      fadeStrength={0}
      fadeDistance={10000}
      sectionSize={0}
      cellSize={1}
      cellColor={cameraYPosition >= 0 ? '#ffffff' : '#e6e7e8'} // #e6e7e8 matches composer
      cellThickness={1}
      side={DoubleSide}
    />
  )
}
