import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

type DetailsItemType = 'Device' | 'Job' | 'Part' | 'NonFunnelJob'

interface DetailsDrawerState {
  data: {
    [index: string]: DetailsDrawerData
  }
  open: boolean
}

interface DetailsContent {
  itemType: DetailsItemType
  selectedIds: string[]
}

interface DetailsDrawerData {
  detailsContent: DetailsContent | undefined
  history: DetailsContent[]
}

const getPageTabKey = (page: string, tab?: string): string => {
  if (!tab) return page
  return `${page}-${tab}`
}

const initialState: DetailsDrawerState = {
  data: {},
  open: false,
} satisfies DetailsDrawerState
const detailsDrawerSlice = createSlice({
  name: 'detailsDrawer',
  initialState,
  reducers: {
    setDrawerOpen(state, action: PayloadAction<{ value: boolean }>) {
      state.open = action.payload.value
    },
    invalidateDetailsItem(
      state,
      action: PayloadAction<{ page: string; tab?: string }>,
    ) {
      const detailsKey = getPageTabKey(action.payload.page, action.payload.tab)
      if (!state.data[detailsKey]) {
        state.data[detailsKey] = {
          history: [],
          detailsContent: undefined,
        }
        return
      }
      state.data[detailsKey].detailsContent = undefined
    },
    invalidateDrawer(state) {
      state.data = {}
    },
    setDetailsItem(
      state,
      action: PayloadAction<{
        page: string
        tab?: string
        itemType: DetailsItemType
        selectedId: string
        isOverlay?: true
      }>,
    ) {
      const detailsKey = getPageTabKey(action.payload.page, action.payload.tab)
      const currentItem = state.data[detailsKey]?.detailsContent

      if (!state.data[detailsKey]) {
        state.data[detailsKey] = {
          history: [],
          detailsContent: undefined,
        }
      }
      // Check if adding as an overlay
      if (action.payload.isOverlay && currentItem !== undefined) {
        // Append current item to history first
        state.data[detailsKey].history.push(currentItem)
      } else {
        // clear history if not an overlay
        state.data[detailsKey].history = []
      }

      // Set current item to new value
      state.data[detailsKey].detailsContent = {
        itemType: action.payload.itemType,
        selectedIds: [action.payload.selectedId],
      }
    },
    setSelectedIds(
      state,
      action: PayloadAction<{
        page: string
        tab?: string
        selectedIds: string[]
      }>,
    ) {
      const detailsKey = getPageTabKey(action.payload.page, action.payload.tab)

      if (!state.data[detailsKey]) return

      const currentDetailsContent = state.data[detailsKey].detailsContent
      if (!currentDetailsContent) return

      state.data[detailsKey].detailsContent = {
        ...currentDetailsContent,
        selectedIds: action.payload.selectedIds,
      }
    },
    addSelectedId(
      state,
      action: PayloadAction<{
        page: string
        tab?: string
        itemType: DetailsItemType
        selectedId: string
      }>,
    ) {
      const detailsKey = getPageTabKey(action.payload.page, action.payload.tab)

      if (!state.data[detailsKey]) {
        state.data[detailsKey] = {
          detailsContent: {
            itemType: action.payload.itemType,
            selectedIds: [action.payload.selectedId],
          },
          history: [],
        }
        return
      }

      const currentDetailsContent = state.data[detailsKey].detailsContent
      if (!currentDetailsContent) {
        state.data[detailsKey].detailsContent = {
          itemType: action.payload.itemType,
          selectedIds: [action.payload.selectedId],
        }
        return
      }

      if (
        currentDetailsContent.selectedIds.some(
          (id) => id === action.payload.selectedId,
        )
      )
        return

      state.data[detailsKey].detailsContent = {
        ...currentDetailsContent,
        selectedIds: [
          ...currentDetailsContent.selectedIds,
          action.payload.selectedId,
        ],
      }
    },
    removeSelectedId(
      state,
      action: PayloadAction<{
        page: string
        tab?: string
        selectedId: string
      }>,
    ) {
      const detailsKey = getPageTabKey(action.payload.page, action.payload.tab)

      if (!state.data[detailsKey]) return

      const currentDetailsContent = state.data[detailsKey].detailsContent
      if (!currentDetailsContent) return

      const updatedSelectedIds = currentDetailsContent.selectedIds.filter(
        (id) => id !== action.payload.selectedId,
      )
      state.data[detailsKey].detailsContent = {
        ...currentDetailsContent,
        selectedIds: updatedSelectedIds,
      }
    },

    closeDetailsOverlay(
      state,
      action: PayloadAction<{ page: string; tab?: string }>,
    ) {
      // Use last history item, then pop item
      const detailsKey = getPageTabKey(action.payload.page, action.payload.tab)

      if (state.data[detailsKey].history.length > 0) {
        // Set the detailsContent to the last item in history
        state.data[detailsKey].detailsContent =
          state.data[detailsKey].history[
            state.data[detailsKey].history.length - 1
          ]

        // Remove the last item from history
        state.data[detailsKey].history.pop()

        // Ensure history is an array (in case it's empty)
        state.data[detailsKey].history = [...state.data[detailsKey].history]
      }
    },
  },
  selectors: {
    selectDrawerOpen: (state): boolean => {
      return state.open === true
    },
    selectDetailsItem: (
      state,
      page: string,
      tab?: string,
    ): DetailsContent | undefined => {
      const detailsKey = getPageTabKey(page, tab)
      return state.data[detailsKey]?.detailsContent
    },
    selectDetailsItemType: (
      state,
      page: string,
      tab?: string,
    ): DetailsItemType | undefined => {
      const detailsKey = getPageTabKey(page, tab)
      return state.data[detailsKey]?.detailsContent?.itemType
    },
    selectDetailsNumSelected: (state, page: string, tab?: string): number => {
      const detailsKey = getPageTabKey(page, tab)
      return state.data[detailsKey]?.detailsContent?.selectedIds.length ?? 0
    },
    selectDetailsItemId: (
      state,
      page: string,
      tab?: string,
    ): string | undefined => {
      const detailsKey = getPageTabKey(page, tab)
      if (state.data[detailsKey]?.detailsContent?.selectedIds.length === 1) {
        return state.data[detailsKey]?.detailsContent?.selectedIds[0]
      }
    },
    selectDetailsIsOverlay: (state, page: string, tab?: string): boolean => {
      const detailsKey = getPageTabKey(page, tab)
      return state.data[detailsKey]?.history?.length > 0
    },
  },
})

export type { DetailsItemType }
export const {
  setDrawerOpen,
  setDetailsItem,
  setSelectedIds,
  addSelectedId,
  removeSelectedId,
  closeDetailsOverlay,
  invalidateDetailsItem,
  invalidateDrawer,
} = detailsDrawerSlice.actions
export const {
  selectDrawerOpen,
  selectDetailsNumSelected,
  selectDetailsItemId,
  selectDetailsItem,
  selectDetailsItemType,
  selectDetailsIsOverlay,
} = detailsDrawerSlice.selectors
export default detailsDrawerSlice.reducer
