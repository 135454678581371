import { TabList as MuiTabList } from '@mui/lab'
import { TabListProps } from '@mui/lab/TabList'
import {
  Badge,
  Box,
  Divider,
  Tab as MuiTab,
  Tabs as MuiTabs,
  Skeleton,
  TabProps,
  TabsProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { MAX_BADGE_SIZE } from '../../constants/badges'
import { usePageTabs, useTablePageTabs } from '../../hooks/tab'
import { RootState } from '../../store/store'

interface TabsHeaderProps {
  title: React.ReactNode
  defaultTabValue: string
  buttons?: React.ReactNode
  tabs: {
    label: React.ReactNode
    icon: React.ReactElement
    value: string
    flashing?: boolean
    badgeContent?: (state: RootState) => number
    whileSelected?: () => void
  }[]
}

const outerTabs = (
  inner: () => React.ReactNode,
  buttons: () => React.ReactNode,
) => (
  <>
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'center',
        width: '100%',
      }}
    >
      {inner()}
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '6px',
        }}
      >
        {buttons()}
      </Box>
    </Box>
    <Divider sx={{ marginBottom: '1em' }} flexItem />
  </>
)

export const Tab = ({
  badgeContent,
  whileSelected,
  selectedTab,
  flashing,
  ...props
}: TabProps & {
  icon: React.ReactNode
  selectedTab: string
  badgeContent?: (state: RootState) => number
  whileSelected?: () => void
  flashing?: boolean
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const badgeSelection = useSelector(badgeContent ?? (() => 0))

  useEffect(() => {
    if (whileSelected && selectedTab === props.value) {
      whileSelected()
    }
  })
  const FlashingDisplay = () => (
    <Skeleton
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0px',
        left: '0px',
        background: 'transparent',
        '&::after': {
          background: (theme) =>
            `linear-gradient( 90deg, transparent, ${alpha(theme.palette.error.light, 0.1)}, transparent )`,
        },
      }}
      animation="wave"
    />
  )
  return (
    <MuiTab
      {...props}
      icon={
        <Badge
          color="error"
          variant="standard"
          badgeContent={badgeSelection}
          max={MAX_BADGE_SIZE}
        >
          {props.icon}
        </Badge>
      }
      sx={{
        textTransform: 'unset',
        '& span': {
          marginRight: ['2px!important', '2px!important', '8px!important'],
        },
        padding: ['2px', '4px', '12px'],
        position: 'relative',
        ...props.sx,
      }}
      {...(mdDown || props.label == null
        ? {
            iconPosition: undefined,
            label: <>{flashing && <FlashingDisplay />}</>,
          }
        : {
            iconPosition: 'start', // eslint-disable-line i18next/no-literal-string
            label: (
              <>
                {flashing && <FlashingDisplay />}
                {props.label}
              </>
            ),
          })}
    />
  )
}

export const Tabs = ({
  tab,
  setTab,
  defaultTab,
  children,
  buttons,
  ...props
}: {
  tab: string | false
  setTab: (tab: string | false) => void
  defaultTab: string
  children: React.ReactNode
  buttons?: React.ReactNode
} & TabsProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (!tab) setTab(defaultTab)
  }, [tab]) // eslint-disable-line react-hooks/exhaustive-deps

  return outerTabs(
    () => (
      <MuiTabs
        aria-label={t('label.tabs')}
        {...props}
        value={tab}
        sx={{
          width: '100%',
          height: smDown ? '3em' : '3.8em',
          ...props.sx,
        }}
        onChange={(event: React.SyntheticEvent, newValue: string) =>
          setTab(newValue)
        }
      >
        {children}
      </MuiTabs>
    ),
    () => buttons,
  )
}

export const TabList = ({
  buttons,
  ...props
}: TabListProps & { buttons?: React.ReactNode }) => {
  return outerTabs(
    () => <MuiTabList {...props} />,
    () => buttons,
  )
}

export const TabsHeader = ({
  title,
  tab,
  changeTab,
  defaultTabValue,
  buttons,
  tabs,
}: TabsHeaderProps & {
  tab: string
  changeTab: (tab: string | false) => void
}) => {
  return (
    <>
      <Typography variant="h1">{title}</Typography>
      <Tabs
        tab={tab}
        setTab={changeTab}
        defaultTab={defaultTabValue}
        buttons={buttons}
      >
        {tabs.map((tabData) => (
          <Tab
            key={tabData.value}
            selectedTab={tab}
            icon={tabData.icon}
            label={tabData.label}
            value={tabData.value}
            badgeContent={tabData.badgeContent}
            whileSelected={tabData.whileSelected}
            flashing={tabData.flashing}
          />
        ))}
      </Tabs>
    </>
  )
}

export const TabsPageHeader = ({
  tabPath,
  onEnterTab,
  defaultTabValue,
  ...props
}: TabsHeaderProps & {
  tabPath: string
  onEnterTab?: (tab: string | false) => void
}) => {
  const tabValues = props.tabs.map((tab) => tab.value)
  const { tab, changeTab } = usePageTabs(tabPath, tabValues, defaultTabValue)

  return (
    <TabsHeader
      tab={tab}
      changeTab={(tab) => {
        changeTab(tab)
        if (onEnterTab) onEnterTab(tab)
      }}
      defaultTabValue={defaultTabValue}
      {...props}
    />
  )
}

export const TabsTablePageHeader = ({
  tabPath,
  table,
  onEnterTab,
  defaultTabValue,
  ...props
}: TabsHeaderProps & {
  tabPath: string
  table: string
  onEnterTab?: (tab: string | false) => void
}) => {
  const tabValues = props.tabs.map((tab) => tab.value)
  const { tab, changeTab } = useTablePageTabs(
    tabPath,
    table,
    tabValues,
    defaultTabValue,
  )

  return (
    <TabsHeader
      tab={tab}
      changeTab={(tab) => {
        changeTab(tab)
        if (onEnterTab) onEnterTab(tab)
      }}
      defaultTabValue={defaultTabValue}
      {...props}
    />
  )
}
