import {
  Box,
  CircularProgress,
  LinearProgress,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { t } from 'i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { EmptyState, PartBodyHeader, PartDataLoading } from '../../components'
import { Dashboard, DashboardLink } from '../../components/Dashboard/Dashboard'
import { DragAndDropUpload } from '../../components/DragAndDropUpload/DragAndDropUpload'
import { AllSvg, QueueListSvg } from '../../components/Icon/Icon'
import {
  PARTS_QUEUE_TABLE_NAME,
  PartsQueueHeader,
  PartsQueueTable,
} from '../../components/PartsQueue/PartsQueue'
import {
  PARTS_TABLE_STATE_NAME,
  PartsTable,
} from '../../components/PartsTable/PartsTable'
import { TabsTablePageHeader } from '../../components/Tabs/Tabs'
import { UnderConstruction } from '../../components/UnderConstruction/UnderConstruction'
import { Tabs } from '../../constants/tabs'
import {
  clientApi,
  useGetPartsApiV1PartsGetQuery,
  useGetTemplatesApiV1TemplatesGetQuery,
} from '../../store/clientApi'
import {
  selectDrawerOpen,
  setDrawerOpen,
} from '../../store/slices/detailsDrawerSlice'
import { selectNumChecked as selectTemplatePartsNumChecked } from '../../store/slices/partQueueSlice'
import { getTab } from '../../store/slices/tabSlice'
import {
  enableCollapsible,
  invalidate,
  selectNumChecked,
  selectQueryArgs,
  selectQueryIsSearching,
  selectRtkData,
  setRtkArgs,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import classes from './parts.module.css'

const tabPath = 'parts'
const defaultTab = Tabs.PARTS.PARTS_QUEUED

interface PartsProps {
  primaryLinks: DashboardLink[]
  footerLinks: DashboardLink[]
}

const PartData = () => {
  const theme = useTheme()
  const breakpointLg = useMediaQuery(theme.breakpoints.up('lg'))

  const tab =
    useSelector((state: RootState) => getTab(state, tabPath)) ?? defaultTab
  const newNumSelected = useSelector((state: RootState) =>
    tab === Tabs.PARTS.PARTS_QUEUED
      ? selectTemplatePartsNumChecked(state)
      : selectNumChecked(state, PARTS_TABLE_STATE_NAME),
  )
  const [numSelected, setNumSelected] = useState(0)

  useEffect(() => {
    if (newNumSelected > numSelected && newNumSelected === 1 && breakpointLg) {
      store.dispatch(setDrawerOpen({ value: true }))
    }
    if (numSelected !== newNumSelected) {
      setNumSelected(newNumSelected)
    }
  }, [newNumSelected, numSelected, breakpointLg])

  const queryArgs = useSelector((state: RootState) =>
    selectQueryArgs(
      state,
      tab === Tabs.PARTS.PARTS_QUEUED
        ? PARTS_QUEUE_TABLE_NAME
        : PARTS_TABLE_STATE_NAME,
    ),
  )

  const rtkArgs = useMemo(
    () =>
      tab === Tabs.PARTS.PARTS_QUEUED
        ? {
            perPage: 1000, // return all templates (the backend needs to be updated to return all)
          }
        : {
            page: queryArgs.page !== 0 ? queryArgs.page - 1 : 0, // pages are zero indexed
            perPage: queryArgs.perPage,
            query:
              queryArgs.searchValue !== ''
                ? `name:"*${queryArgs.searchValue}*"`
                : undefined,
            sorting:
              queryArgs.sortField !== ''
                ? `${queryArgs.sortField}:${queryArgs.sortOrder === 'asc' ? '1' : '-1'}`
                : 'id:1',
            withThumbnails: true,
          },
    [queryArgs, tab],
  )

  useEffect(() => {
    store.dispatch(
      setRtkArgs({
        name:
          tab === Tabs.PARTS.PARTS_QUEUED
            ? PARTS_QUEUE_TABLE_NAME
            : PARTS_TABLE_STATE_NAME,
        value: rtkArgs,
      }),
    )
    store.dispatch(enableCollapsible({ name: PARTS_QUEUE_TABLE_NAME }))
  }, [rtkArgs, tab])

  useGetPartsApiV1PartsGetQuery(rtkArgs, {
    skip: tab !== Tabs.PARTS.ALL_PARTS,
  })
  useGetTemplatesApiV1TemplatesGetQuery(rtkArgs, {
    skip: tab !== Tabs.PARTS.PARTS_QUEUED,
  })

  return <>{null}</>
}

const PartContentInner = () => {
  const tab = useSelector((state: RootState) => getTab(state, tabPath))
  const hasContent = useSelector((state: RootState) => {
    const tab = getTab(state, tabPath)
    let table = PARTS_TABLE_STATE_NAME
    if (tab === Tabs.PARTS.PARTS_QUEUED) table = PARTS_QUEUE_TABLE_NAME

    const query =
      tab === Tabs.PARTS.PARTS_QUEUED
        ? selectRtkData(
            state,
            table,
            clientApi.endpoints.getTemplatesApiV1TemplatesGet.select,
          )
        : selectRtkData(
            state,
            table,
            clientApi.endpoints.getPartsApiV1PartsGet.select,
          )
    const isSearching = selectQueryIsSearching(state, table)
    const totalCount = query?.data?.total_count ?? 0
    const count = query?.data?.content?.length ?? 0
    return (
      count > 0 ||
      totalCount > 0 ||
      isSearching ||
      (query?.isLoading && query?.data === undefined)
    )
  })

  const getEmptyState = () => {
    switch (tab) {
      case Tabs.PARTS.ALL_PARTS:
        return (
          <EmptyState
            image={'/images/no-parts-allparts-tab.svg'}
            title={t('parts:emptyPartsMessages.allParts.title')}
            message={t('parts:emptyPartsMessages.allParts.message')}
          />
        )
      case Tabs.PARTS.PARTS_QUEUED:
        return (
          <EmptyState
            image={'/images/noPartsToReview.svg'}
            title={t('parts:emptyPartsMessages.printReady.title')}
            message={t('parts:emptyPartsMessages.printReady.message')}
          />
        )
      default:
        return <EmptyState />
    }
  }

  let table = PARTS_TABLE_STATE_NAME
  if (tab === Tabs.PARTS.PARTS_QUEUED) table = PARTS_QUEUE_TABLE_NAME

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      {/* Action bar */}
      {/* Main Table */}
      {tab === Tabs.PARTS.PARTS_QUEUED ? (
        <>
          <PartsQueueHeader />
          <PartsQueueTable />
        </>
      ) : (
        <>
          <PartBodyHeader table={table} actionBarType={'parts'} />
          <PartsTable table={PARTS_TABLE_STATE_NAME} />
        </>
      )}
      <PartDataLoading table={table} />
      {!hasContent && getEmptyState()}
    </Box>
  )
}

const PartContent = () => {
  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      PARTS_TABLE_STATE_NAME,
      clientApi.endpoints.getPartsApiV1PartsGet.select,
    )

    return query?.isLoading && query?.isUninitialized
  })

  return isLoading ? (
    <>
      <LinearProgress />
    </>
  ) : (
    <PartContentInner />
  )
}

const Header = () => {
  const { t } = useTranslation('parts')
  const invalidateTable = () =>
    store.dispatch(invalidate(PARTS_TABLE_STATE_NAME))

  return (
    <TabsTablePageHeader
      tabPath={tabPath}
      table={PARTS_TABLE_STATE_NAME}
      title={t('title.parts')}
      defaultTabValue={defaultTab}
      onEnterTab={() => invalidateTable()}
      tabs={[
        {
          label: t('label.allParts'),
          value: Tabs.PARTS.ALL_PARTS,
          icon: <AllSvg color="inherit" />,
        },
        {
          label: t('label.partsQueue'),
          value: Tabs.PARTS.PARTS_QUEUED,
          icon: <QueueListSvg color="inherit" />,
        },
      ]}
    />
  )
}

const Parts: FC<PartsProps> = (props) => {
  const theme = useTheme()
  const breakpointLg = useMediaQuery(theme.breakpoints.up('lg'))
  const { releasePartsList } = useFlags()
  const [isReleaseFlagTimedout, setIsReleaseFlagTimedout] =
    useState<boolean>(false)

  // States
  const inspectorOpen = useSelector((state: RootState) =>
    selectDrawerOpen(state),
  )

  // Feature Flag timeout as both error and loading are treated as undefined
  useEffect(() => {
    let timeoutId: any = null
    // Start timeout if releasePartsList is undefined
    if (!releasePartsList) {
      timeoutId = setTimeout(() => {
        setIsReleaseFlagTimedout(true)
      }, 3000) // 3 seconds
    }

    // Clear timeout on component unmount or if releasePartsList becomes defined
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [releasePartsList])

  return (
    <>
      <PartData />
      <Dashboard
        primaryLinks={props.primaryLinks}
        footerLinks={props.footerLinks}
      >
        {releasePartsList === true ? (
          <>
            <Box
              component="div"
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                gap: '1em',
                position: 'relative',
              }}
            >
              <DragAndDropUpload
                sx={{
                  display: !breakpointLg && inspectorOpen ? 'none' : undefined,
                }}
              >
                <Box
                  component="div"
                  className={classes.mainContainer}
                  sx={{
                    position: 'relative',
                    maxWidth: {
                      sm: 'calc(100vw - 8px)',
                      md: 'calc(100vw - 80px - 8px)',
                      lg: 'calc(100vw - 100px - 8px)',
                    },
                    maxHeight: '100%',
                    minHeight: '100%',
                    bgcolor: (theme: Theme) => theme.surface.low,
                  }}
                >
                  <Header />
                  <PartContent />
                </Box>
              </DragAndDropUpload>
            </Box>
          </>
        ) : releasePartsList === undefined && !isReleaseFlagTimedout ? (
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CircularProgress size={24} />
          </Box>
        ) : (
          <UnderConstruction />
        )}
      </Dashboard>
    </>
  )
}

export { Parts }
