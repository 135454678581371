import { Box, Divider, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { Viewport } from '../../'
import { partClassification } from '../../../constants/partClassification'
import {
  PartClassification,
  useGetPartApiV1PartsPartIdGetQuery,
} from '../../../store/clientApi'
import {
  selectDetailsItemId,
  selectDetailsNumSelected,
  setDrawerOpen,
} from '../../../store/slices/detailsDrawerSlice'
import {
  availableViewportModel,
  selectModelDownloadError,
  selectModelLoaded,
} from '../../../store/slices/viewportModelSlice'
import { RootState, store } from '../../../store/store'
import { ModelFileTypes } from '../../../typed/ModelFileTypes'
import { rotationDegreesToEuler } from '../../../utils/geometryUtils'
import { getModelFromIndexedDB } from '../../../utils/indexeddb'
import { PartProgressChecklist } from '../../Checklist/PartProgressChecklist'
import { Model } from '../../Model/Model'
import {
  PartButtonGroup,
  PartButtonGroupType,
} from '../../PartActionBar/buttons'
import {
  INBOX_TABLE_STATE_NAME,
  PARTS_TABLE_STATE_NAME,
} from '../../PartsTable/PartsTable'
import { ViewportContainer, ViewportLoading } from '../../Viewport/Viewport'
import {
  DetailsGrid,
  ExtendedDetails,
} from '../ExtendedDetails/ExtendedDetails'

interface PartViewportProps {
  actionBarButtons?: ReactNode
}

const PartViewport: FC<PartViewportProps> = ({ actionBarButtons }) => {
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const [modelFile, setModelFile] = useState<
    | {
        modelId: string
        modelData: {
          data: ArrayBuffer
          type: ModelFileTypes
        }
      }
    | undefined
  >()

  const atMostOneSelected = useSelector(
    (state: RootState) =>
      selectDetailsNumSelected(state, basePath, selectedTab) < 2,
  )

  const selectedPartId = useSelector((state: RootState) =>
    selectDetailsItemId(state, basePath, selectedTab),
  )

  const modelLoaded = useSelector((state: RootState) =>
    selectedPartId ? selectModelLoaded(state, selectedPartId) : false,
  )

  const { partName, selectedPartStatus } = useGetPartApiV1PartsPartIdGetQuery(
    {
      partId: Number(selectedPartId),
    },
    {
      selectFromResult: ({ data }) => {
        return {
          partName: data?.name,
          selectedPartStatus: data?.status,
        }
      },
      skip: selectedPartId === undefined,
    },
  )

  const modelViewportError = useSelector((state: RootState) =>
    selectModelDownloadError(state, selectedPartId ?? '0'),
  )

  const isSelected = selectedPartId !== undefined

  const loadModelFile = async () => {
    if (selectedPartId === undefined) return

    const modelAvailable = await store.dispatch(
      availableViewportModel({
        partId: parseInt(selectedPartId),
        partStatus: selectedPartStatus,
      }),
    )
    if (modelAvailable) {
      const modelFileData = await getModelFromIndexedDB(selectedPartId, 'PART')
      if (modelFileData !== null) {
        setModelFile({ modelId: selectedPartId, modelData: modelFileData })
      }
    }
  }

  useEffect(() => {
    if (selectedPartId === undefined || selectedPartStatus === undefined) {
      return
    }
    if (modelFile && selectedPartId !== modelFile.modelId) {
      setModelFile(undefined)
    }

    loadModelFile()
  }, [selectedPartId, selectedPartStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (modelLoaded && modelFile === undefined) {
      loadModelFile()
    }
  }, [modelLoaded, modelFile]) // eslint-disable-line

  if (selectedPartId && atMostOneSelected) {
    if (partName && modelFile) {
      return (
        <Sentry.ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag('location', 'part inspector model viewer')
          }}
          fallback={
            <>
              <ViewportContainer sx={{ height: '100%' }}>
                <img alt="" src="/images/inspectorNoParts.svg" />
              </ViewportContainer>
            </>
          }
        >
          <Viewport
            modelName={partName}
            maximisedFooter={
              selectedPartId && (
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    gap: '6px',
                  }}
                >
                  {actionBarButtons}
                </Box>
              )
            }
          >
            <Model
              file={modelFile.modelData}
              rotation={rotationDegreesToEuler(90, 0, 0)}
            />
          </Viewport>
        </Sentry.ErrorBoundary>
      )
    } else if (!modelViewportError) {
      return <ViewportLoading modelId={selectedPartId ?? '0'} />
    } else {
      return (
        <ViewportContainer sx={{ height: '100%' }}>
          <img alt="" src="/images/inspectorNoParts.svg" />
        </ViewportContainer>
      )
    }
  } else if (!isSelected && atMostOneSelected) {
    return (
      <ViewportContainer sx={{ height: '100%' }}>
        <img
          id="inspector-no-parts-img"
          alt=""
          src="/images/inspectorNoParts.svg"
        />
      </ViewportContainer>
    )
  } else {
    return (
      <ViewportContainer sx={{ height: '100%' }}>
        <img
          id="inspector-multiple-parts-img"
          alt=""
          src="/images/inspectorMultipleSelected.svg"
        />
      </ViewportContainer>
    )
  }
}

const ActionBarButtons = () => {
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]

  switch (basePath) {
    case 'inbox':
      return (
        <PartButtonGroup
          table={INBOX_TABLE_STATE_NAME}
          isInspectorPanel={true}
          type={selectedTab as PartButtonGroupType}
        />
      )
    case 'parts':
      return (
        <PartButtonGroup
          table={PARTS_TABLE_STATE_NAME}
          isInspectorPanel={true}
          type={'parts'}
        />
      )
    default:
      return <></>
  }
}

const PartDetailsContent = () => {
  const { t, i18n } = useTranslation('inbox')
  const UNAVAILABLE = t('common:loading.unavailable')
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]

  const selectedPartId = useSelector((state: RootState) =>
    selectDetailsItemId(state, basePath, selectedTab),
  )

  const { classification, progress_status, created_at, source } =
    useGetPartApiV1PartsPartIdGetQuery(
      {
        partId: Number(selectedPartId),
      },
      {
        selectFromResult: ({ data }) => {
          return {
            classification: data?.classification,
            progress_status: data?.progress_status,
            created_at: data?.created_at,
            source: data?.source,
          }
        },
        skip: selectedPartId === undefined,
      },
    )

  const getPartType = (partType: PartClassification) => {
    const classification = partClassification[partType]
    return t(
      /*
        t('other')
        t('hollowModel')
        t('solidModel')
        t('crownOrToothOrVeneer')
        t('screwInTooth')
        t('die')
        t('flatGingiva')
        t('pillowGingiva')
        t('teethRow')
        t('dentureBase')
        t('denturePartial')
        t('tryInDenture')
        t('occlusalSplint')
        t('surgicalGuide')
        t('bridge')
        t('partialFramework')
        */
      `table.partClassification.${classification}`,
    )
  }

  const partInformation = [
    {
      name: t('partInspector.partType'),
      value: classification ? getPartType(classification) : UNAVAILABLE,
    },
    {
      name: t('partInspector.received'),
      value: created_at
        ? new Date(created_at).toLocaleString([...i18n.languages], {
            day: '2-digit',
            month: 'long',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          })
        : '-',
    },
    {
      name: t('partInspector.source'),
      value: source === '' || source == null ? t('table.userUpload') : source,
    },
  ]

  return (
    <Box component="div" sx={{ display: 'flex', gap: '2em' }}>
      <DetailsGrid details={partInformation} rows={partInformation.length} />
      <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography fontSize={'0.9em'} variant="subtitle1">
          {t('partInspector.progress')}
        </Typography>
        <PartProgressChecklist status={progress_status} />
      </Box>
    </Box>
  )
}

const Title = () => {
  const { t } = useTranslation('inbox')
  const UNAVAILABLE = t('common:loading.unavailable')
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]

  const selectedPartId = useSelector((state: RootState) =>
    selectDetailsItemId(state, basePath, selectedTab),
  )

  const atMostOneSelected = useSelector((state: RootState) =>
    selectDetailsNumSelected(state, basePath, selectedTab),
  )

  const { name } = useGetPartApiV1PartsPartIdGetQuery(
    {
      partId: Number(selectedPartId),
    },
    {
      selectFromResult: ({ data }) => {
        return {
          name: data?.name,
        }
      },
      skip: selectedPartId === undefined,
    },
  )

  return (
    <>
      {(atMostOneSelected === 0 && !selectedPartId) || atMostOneSelected > 1
        ? t('partInspector.title', {
            count: atMostOneSelected,
          })
        : name ?? UNAVAILABLE}
    </>
  )
}

export const PartDetails: FC = () => {
  const { t } = useTranslation('inbox')
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]

  const selectedPartId = useSelector((state: RootState) =>
    selectDetailsItemId(state, basePath, selectedTab),
  )

  const { isLoading, error } = useGetPartApiV1PartsPartIdGetQuery(
    {
      partId: Number(selectedPartId),
    },
    {
      selectFromResult: ({ isLoading, error }) => {
        return {
          isLoading,
          error,
        }
      },
      skip: selectedPartId === undefined,
    },
  )

  return (
    <>
      {error ? (
        <Box component="div">
          <img
            alt=""
            src="/images/inspectorNoParts.svg"
            style={{
              padding: '1em',
              boxSizing: 'border-box',
              height: '100%',
            }}
          />
        </Box>
      ) : (
        <ExtendedDetails
          loading={isLoading}
          title={<Title />}
          close={() => store.dispatch(setDrawerOpen({ value: false }))}
          banner={
            <>
              <PartViewport actionBarButtons={<ActionBarButtons />} />
              <Divider />
            </>
          }
          entries={{
            ...(selectedPartId && {
              [t('partInspector.partInformation')]: {
                startOpen: true,
                content: <PartDetailsContent />,
              },
            }),
          }}
          footer={
            selectedPartId && (
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '6px',
                }}
              >
                <ActionBarButtons />
              </Box>
            )
          }
        />
      )}
    </>
  )
}
