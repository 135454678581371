import { ProgressStatus } from '../store/clientApi'

export enum partIdentification {
  'partIdentification' = 0,
  'identifyingPartType' = 1,
  'confirmPartType' = 2,
  'partTypeConfirmed' = 3,
}

export enum acceptPartForPrinting {
  'acceptPartForPrinting' = 0,
  'partAccepted' = 1,
}

export enum orientatePart {
  'orientatePart' = 0,
  'orientatingPart' = 1,
  'orientationFailed' = 2,
  'partOrientated' = 3,
}

export enum nestPrintJob {
  'nestPrintJob' = 0,
  'partQueued' = 1,
  'nestingPrintJob' = 2,
  'nestingFailed' = 3,
  'addedToPrintJob' = 4,
}

export enum approvePrintJob {
  'approvePrintJob' = 0,
  'printJobPendingApproval' = 1,
  'printJobApproved' = 2,
}

export enum sendToPrinter {
  'sendToPrinter' = 0,
  'slicingPrintJob' = 1,
  'slicingFailed' = 2,
  'sendingToPrinter' = 3,
  'sendingFailed' = 4,
  'printJobDownloaded' = 5,
  'sentToPrinter' = 6,
}

export enum print {
  'print' = 0,
  'printing' = 1,
  'printFailed' = 2,
  'printed' = 3,
}

export enum PartProgressStep {
  'partIdentification' = 0,
  'acceptPartForPrinting' = 1,
  'orientatePart' = 2,
  'nestPrintJob' = 3,
  'approvePrintJob' = 4,
  'sendToPrinter' = 5,
  'print' = 6,
  'rejected' = 7,
}

export const partProgressToProgressStep = (
  status: ProgressStatus,
): PartProgressStep => {
  switch (status) {
    case 0:
    case 1:
    case 2:
      return 0
    case 3:
    case 4:
      return 1
    case 5:
      return 7
    case 6:
    case 7:
      return 2
    case 8:
    case 9:
    case 10:
      return 3
    case 11:
      return 4
    case 12:
    case 13:
    case 14:
    case 15:
      return 5
    case 16:
    case 17:
    case 18:
      return 6
    case 19:
      return 5
  }
}
