import {
  Box,
  IconButton,
  LinearProgress,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { Tabs } from '../../constants/tabs'
import { ensureError, localizeError } from '../../helpers'
import {
  clientApi,
  DeviceStatusPaginatedListRead,
  DeviceStatusRead,
  useRemoveDeviceApiV1DevicesSerialNumberDeleteMutation,
} from '../../store/clientApi'
import {
  selectDevicesInErrorState,
  setDevicesInErrorState,
} from '../../store/slices/devicesSlice'
import { getTab } from '../../store/slices/tabSlice'
import {
  selectRtkData,
  selectTableChecked,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { IsDeviceInAnErrorState } from '../../utils/deviceErrorState'
import { Button } from '../Button/Button'
import { CardGrid } from '../CardGrid/CardGrid'
import { DeviceCard } from '../DeviceCard/DeviceCard'
import { DialogBox } from '../DialogBox'
import { EmptyState } from '../EmptyState/EmptyState'
import { AddSvg, MachinesSvg, WarningSvg } from '../Icon/Icon'
import { TabsPageHeader } from '../Tabs/Tabs'
import { UnderConstruction } from '../UnderConstruction/UnderConstruction'

const tabPath = 'devices'
const defaultTab = Tabs.DEVICES.ALL
const enrolDeviceLink = `https://${import.meta.env.VITE_AUTH0_DOMAIN}/activate`

const selectDevices = createSelector(
  [
    ({ res }: { res?: DeviceStatusPaginatedListRead }) => res?.content,
    ({ tab }: { tab: string }) => tab,
  ],
  (content, tab) =>
    content?.filter((device) =>
      tab === Tabs.DEVICES.ATTENTION ? IsDeviceInAnErrorState(device) : true,
    ),
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
)

const AddDevice = () => {
  const theme = useTheme()
  const { t } = useTranslation('devices')

  const isDeviceSelected = useSelector((state: RootState) => {
    const checked = selectTableChecked(state, DEVICES_TABLE_STATE_NAME)
    return (
      Object.keys(checked).find((thingName) => checked[thingName]) !== undefined
    )
  })

  const mediaQueryBreakpoint = isDeviceSelected ? 'lg' : 'sm'
  const iconOnly = useMediaQuery(theme.breakpoints.up(mediaQueryBreakpoint))

  return iconOnly ? (
    <Button
      startIcon={<AddSvg color="inherit" />}
      color="secondary"
      href={enrolDeviceLink}
      target="_blank"
    >
      {t('button.add')}
    </Button>
  ) : (
    <IconButton href={enrolDeviceLink} target="_blank">
      <AddSvg color="inherit" />
    </IconButton>
  )
}

const Header = () => {
  const { t } = useTranslation('devices')
  const theme = useTheme()

  const isAttentionFlashing = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      DEVICES_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    const tab = getTab(state, tabPath) ?? defaultTab

    const currentlyErroredDevices =
      selectDevices({
        res: query?.data,
        tab: Tabs.DEVICES.ATTENTION,
      })?.map((device) => device.thing_name) ?? []
    const seenErroredDevices = new Set(selectDevicesInErrorState(state))

    const areDevicesErrored =
      currentlyErroredDevices !== undefined &&
      currentlyErroredDevices.length !== 0

    const haveDevicesNotBeenSeen = currentlyErroredDevices.some(
      (device) => !seenErroredDevices.has(device),
    )
    return (
      areDevicesErrored &&
      haveDevicesNotBeenSeen &&
      tab !== Tabs.DEVICES.ATTENTION
    )
  })
  return (
    <TabsPageHeader
      title={t('title.devices')}
      defaultTabValue={defaultTab}
      buttons={<AddDevice />}
      tabs={[
        {
          label: t('label.devices'),
          icon: <MachinesSvg color="inherit" />,
          value: Tabs.DEVICES.ALL,
        },
        {
          label: t('label.requiresAttention'),
          icon: <WarningSvg color={theme.palette.warning.main} />,
          value: Tabs.DEVICES.ATTENTION,
          flashing: isAttentionFlashing,
        },
      ]}
      tabPath={tabPath}
    />
  )
}

const DevicesGrid = () => {
  const [confirmRemove, setConfirmRemove] = useState<string | undefined>(
    undefined,
  )
  const [removeDevice] = useRemoveDeviceApiV1DevicesSerialNumberDeleteMutation()
  const { t } = useTranslation('devices')
  const tab =
    useSelector((state: RootState) => getTab(state, tabPath)) ?? defaultTab

  const selectThingNamesFromDevices = useMemo(() => {
    const emptyArray: DeviceStatusRead[] = []
    return createSelector(
      [(res?: DeviceStatusRead[]) => res ?? emptyArray],
      (content) => content.map((device) => device.thing_name),
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [])

  const devices = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      DEVICES_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    const tab = getTab(state, tabPath) ?? defaultTab
    return selectThingNamesFromDevices(
      selectDevices({ res: query?.data, tab: tab }),
    )
  })

  useEffect(() => {
    if (tab === Tabs.DEVICES.ATTENTION) {
      store.dispatch(setDevicesInErrorState(devices))
    }
  }, [tab, devices])

  return devices.length > 0 ? (
    <>
      <CardGrid>
        {devices.map((thingName) => {
          return (
            <Box
              component="div"
              sx={{
                height: 'fit-content',
              }}
              key={`build-${thingName}`}
            >
              <DeviceCard
                thingName={thingName}
                setConfirmRemove={setConfirmRemove}
              />
            </Box>
          )
        })}
      </CardGrid>
      <DialogBox
        title={t('title.removeDevice')}
        message={t('message.warnRemoveDevice')}
        open={confirmRemove !== undefined}
        setOpen={(open) => {
          if (!open) setConfirmRemove(undefined)
        }}
        args={undefined}
        onClose={(approve) => {
          if (confirmRemove && approve) {
            try {
              removeDevice({
                serialNumber: confirmRemove,
              }).unwrap()
            } catch (err) {
              const error = ensureError(err)
              toast.error(localizeError(t, error))
            }
          }
        }}
      ></DialogBox>
    </>
  ) : tab === Tabs.DEVICES.ATTENTION ? (
    <EmptyState
      image={'/images/no-attention-required.svg'}
      title={t('devices:message.allGood')}
      message={t('devices:message.noDevicesNeedAttention')}
    />
  ) : (
    <EmptyState
      image={'/images/no-devices.svg'}
      title={t('devices:message.devicesPageInfo')}
      message={t('devices:message.addDevices')}
      button={
        <Button
          color="primary"
          href={enrolDeviceLink}
          target="_blank"
          sx={{
            mt: '1.5em',
          }}
        >
          {t('button.add')}
        </Button>
      }
    />
  )
}

const DevicesList = () => {
  const theme = useTheme()
  const { t } = useTranslation('devices')

  const error = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      DEVICES_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return query?.error
  })

  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      DEVICES_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return (
      query?.isLoading && (query?.isUninitialized || query?.data === undefined)
    )
  })

  const isDeviceSelected = useSelector((state: RootState) => {
    const checked = selectTableChecked(state, DEVICES_TABLE_STATE_NAME)
    return (
      Object.keys(checked).find((thingName) => checked[thingName]) !== undefined
    )
  })

  const hasDevices = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      DEVICES_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return query?.data !== undefined
  })

  const { allowDevicesUi } = useFlags()
  const [isallowDevicesUiTimedOut, setIsallowDevicesUiTimedOut] =
    useState<boolean>(false)

  // Feature Flag timeout as both error and loading are treated as undefined
  useEffect(() => {
    let timeoutId: any = null
    // Start timeout if allowDevicesUi is undefined
    if (!allowDevicesUi) {
      timeoutId = setTimeout(() => {
        setIsallowDevicesUiTimedOut(true)
      }, 3000) // 3 seconds
    }

    // Clear timeout on component unmount or if allowDevicesUi becomes defined
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [allowDevicesUi])

  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignContent: 'stretch',
          justifyContent: 'space-evenly',
          gap: '1em',
          width: '100%',
          height: '100%',
        }}
      >
        {error ? (
          <>{localizeError(t, ensureError(error))}</>
        ) : isLoading ||
          (allowDevicesUi === undefined && !isallowDevicesUiTimedOut) ? (
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              bgcolor: (theme: Theme) => theme.surface.low,
              padding: '1em',
              borderRadius: '1em',
              overflow: 'auto',
            }}
          >
            <Header />
            <LinearProgress />
          </Box>
        ) : hasDevices ? (
          <>
            <Box
              component="div"
              sx={{
                display: mdDown
                  ? !isDeviceSelected
                    ? 'flex'
                    : 'none'
                  : 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                bgcolor: (theme: Theme) => theme.surface.low,
                padding: '1em',
                borderRadius: '1em',
                overflow: 'auto',
              }}
            >
              <Header />
              <DevicesGrid />
            </Box>
          </>
        ) : isallowDevicesUiTimedOut || allowDevicesUi === false ? (
          <UnderConstruction />
        ) : null}
      </Box>
    </>
  )
}

export const DEVICES_TABLE_STATE_NAME = 'devicesTableState'

export { DevicesList }
