import { useFrame, useThree } from '@react-three/fiber'
import { FC, useEffect, useState } from 'react'
import theme from '../../theme'
import { rotationDegreesToEuler } from '../../utils/geometryUtils'
import { Model } from '../Model/Model'

interface BuildPlatformProps {
  type: BuildPlatformTypes
}
const BuildPlatformFileURLs = {
  MAX: new URL('../../assets/models/build-platforms/MAX.ply', import.meta.url)
    .href,
  'MAX-X': new URL(
    '../../assets/models/build-platforms/MAX-X.ply',
    import.meta.url,
  ).href,
  'PRO-4K': new URL(
    '../../assets/models/build-platforms/PRO-4K.ply',
    import.meta.url,
  ).href,
  'PRO-4K-0122': new URL(
    '../../assets/models/build-platforms/PRO-4K-0122.ply',
    import.meta.url,
  ).href,
  'PRO-4K-0176': new URL(
    '../../assets/models/build-platforms/PRO-4K-0176.ply',
    import.meta.url,
  ).href,
  ULTRA: new URL(
    '../../assets/models/build-platforms/ULTRA.ply',
    import.meta.url,
  ).href,
}

export type BuildPlatformTypes =
  | 'MAX'
  | 'MAX-X'
  | 'PRO-4K'
  | 'PRO-4K-0122'
  | 'PRO-4K-0176'
  | 'ULTRA'

export const BuildPlatform: FC<BuildPlatformProps> = ({ type }) => {
  const { camera, invalidate } = useThree()
  const [cameraYPosition, setCameraYPosition] = useState(camera.position.y)
  const [buildPlatformFile, setBuildPlatformFile] = useState<
    { data: ArrayBuffer; type: 'ply' } | undefined
  >()

  useFrame(() => {
    // Check if the camera Y position has changed
    if (camera.position.y !== cameraYPosition) {
      setCameraYPosition(camera.position.y)
      invalidate() // Force re-render when camera position changes
    }
  })

  useEffect(() => {
    // Load the Build Platform based on type
    const loadPLYFile = async () => {
      try {
        const buildPlatformModelURL = BuildPlatformFileURLs[type]
        const response = await fetch(buildPlatformModelURL)
        const blob = await response.blob()
        const arrayBuffer = await blob.arrayBuffer()
        setBuildPlatformFile({ data: arrayBuffer, type: 'ply' })
      } catch (error) {
        console.error('Error loading Build Platform PLY file:', error)
      }
    }

    loadPLYFile()
  }, [type])

  // Return the Model component with the camera's Y position check
  return (
    <Model
      file={buildPlatformFile}
      outsideBounds
      rotation={rotationDegreesToEuler(-90, 0, 0)}
      color={theme.model.buildPlatform}
      visible={cameraYPosition >= 0} // Use the updated camera Y position
    />
  )
}
