import {
  Box,
  CircularProgress,
  LinearProgress,
  Theme,
  useTheme,
} from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  FC,
  UIEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
  WheelEvent,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { EmptyState } from '../../components'
import { Button } from '../../components/Button/Button'
import { Dashboard, DashboardLink } from '../../components/Dashboard/Dashboard'
import { UnderConstruction } from '../../components/UnderConstruction/UnderConstruction'
import { Tabs } from '../../constants/tabs'
import { ensureError, localizeError } from '../../helpers'
import { usePageTabs } from '../../hooks/tab'
import { clientApi } from '../../store/clientApi'
import { selectRtkData } from '../../store/slices/tableSlice'
import { RootState } from '../../store/store'
import { TimelineHeader } from './TimelineHeader/TimelineHeader'
import {
  MIN_HOUR_WIDTH,
  TimelineHourMarker,
} from './TimelineHourMarker/TimelineHourMarker'
import { TimelinePrinters } from './TimelinePrinters/TimelinePrinters'
import { TimelineRows } from './TimelineRows/TimelineRows'

interface TimelineProps {
  primaryLinks: DashboardLink[]
  footerLinks: DashboardLink[]
}

const enrolDeviceLink = `https://${import.meta.env.VITE_AUTH0_DOMAIN}/activate`
const PRINTER_PANEL_WIDTH = 300
const PRINTER_PANEL_PADDING = 28
const FADE_HEIGHT = 32

/**
 * @param width Width in pixels of the inner timeline panel (i.e., printers list + timeline window + padding / gaps)
 * @returns The width in pixels of the scrollable timeline that fits within the screen
 */
const calcHoursViewWidth = (width: number) => {
  const padding = 24
  const screenWidth =
    width - PRINTER_PANEL_WIDTH - PRINTER_PANEL_PADDING * 3 - padding

  return screenWidth
}

/**
 *
 * @param width Width in pixels of the inner timeline panel (i.e., printers list + timeline window + padding / gaps)
 * @param timelineHours The number of hours to show within the width of the screen view
 * @returns
 */
const calcTimelineWidth = (width: number, timelineHours: number) => {
  const screenWidth = width - PRINTER_PANEL_WIDTH - PRINTER_PANEL_PADDING * 3
  const minWidth = MIN_HOUR_WIDTH * timelineHours
  return Math.max(screenWidth, minWidth)
}

const Timeline: FC<TimelineProps> = ({ primaryLinks, footerLinks }) => {
  // hooks
  usePageTabs('timeline', [Tabs.TIMELINE.ALL])
  const { t } = useTranslation('timeline')
  const theme = useTheme()
  const { allowDevicesUi } = useFlags()

  // states
  const [featureFlagTimeout, setFeatureFlagTimeout] = useState<boolean>(false)
  const [timelineHours, setTimelineHours] = useState<number>(12)
  const [timelineWidth, setTimelineWidth] = useState(
    MIN_HOUR_WIDTH * timelineHours - PRINTER_PANEL_WIDTH,
  )
  const [timelineHeight, setTimelineHeight] = useState(0)
  const [hoursViewWidth, setHoursViewWidth] = useState(
    MIN_HOUR_WIDTH * timelineHours,
  )

  const maxTimelineHours = useSelector(
    (state: RootState) => state.timelineDurationSlice.hours,
  )

  // Constants
  // calculate timeline start time (2hrs 20 before)
  const currentTime = new Date(Date.now()).setSeconds(0, 0) // update once per minute
  const flooredHourTime = new Date(currentTime - 1000 * 7200).setMinutes(0, 0, 0) // prettier-ignore
  const startTime = new Date(flooredHourTime - 1000 * 60 * 20) // Subtract 20 minutes (padding-left)

  // Refs
  const printersRef = useRef<HTMLDivElement | null>(null)
  const timelineRef = useRef<HTMLDivElement | null>()
  const scrollRef = useRef<HTMLDivElement | undefined>()

  const handleSetPrintersHeight = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setTimelineHeight(node.clientHeight)
    }
  }, [])

  /**
   * Scrolls the printers list when the target scroll event changes
   * @param e onScroll event
   */
  const handleScrollTimeline: UIEventHandler<HTMLDivElement> = (e) => {
    const printers = printersRef.current
    if (printers) {
      printers.style.transform = `translateY(-${e.currentTarget.scrollTop}px)`
    }
  }

  const timelineRefCallback = useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        setTimelineWidth(calcTimelineWidth(node.clientWidth, timelineHours))
        setHoursViewWidth(calcHoursViewWidth(node.clientWidth))
      }
    },
    [timelineHours],
  )

  useEffect(() => {
    // onResize handles resize events with a 50 ms debounce
    let resizeId: NodeJS.Timeout | null = null
    const onResize = () => {
      if (resizeId) clearTimeout(resizeId)
      resizeId = setTimeout(() => {
        if (timelineRef.current) {
          setTimelineWidth(
            calcTimelineWidth(timelineRef.current.clientWidth, timelineHours),
          )
          setHoursViewWidth(calcHoursViewWidth(timelineRef.current.clientWidth))
        }
      }, 50)
    }

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [timelineHours])

  // Feature Flag timeout as both error and loading are treated as undefined
  useEffect(() => {
    let timeoutId: any = null
    // Start timeout if allowDevicesUi is undefined
    if (!allowDevicesUi) {
      timeoutId = setTimeout(() => {
        setFeatureFlagTimeout(true)
      }, 3000) // 3 seconds
    }

    // Clear timeout on component unmount or if allowDevicesUi becomes defined
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [allowDevicesUi])

  const hasDevices = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return (query?.data?.content?.length ?? 0) > 0
  })

  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return (
      query?.isLoading && (query?.isUninitialized || query?.data === undefined)
    )
  })

  const error = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return query?.error
  })

  // Timeline page width excluding left navbar
  const TimelineMaxWidths = (theme: Theme) => ({
    [theme.breakpoints.up('xs')]: {
      maxWidth: 'calc(100vw)',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'calc(100vw - 80px)',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100vw - 100px)',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 'calc(100vw - 120px)',
    },
  })

  return (
    <Dashboard primaryLinks={primaryLinks} footerLinks={footerLinks}>
      {allowDevicesUi ? (
        <Box
          component="div"
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            boxSizing: 'border-box',
            maxWidth: '100%',
            overflow: 'hidden',
            gap: `${PRINTER_PANEL_PADDING}px`,
          }}
        >
          <Box
            component="div"
            ref={(ref: HTMLDivElement | null) => {
              timelineRefCallback(ref)
              timelineRef.current = ref
            }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'stretch',
              justifyContent: 'flex-start',
              width: '100%',
              height: '100%',
              maxHeight: '100%',
              backgroundColor: theme.surface.low,
              borderRadius: '1em',
              boxSizing: 'border-box',
              maxWidth: '100%',
              overflow: 'hidden',
            }}
          >
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'max-content',
                width: '100%',
                bgcolor: (theme: Theme) => theme.surface.low,
                padding: '1em 1em 0em 1em',
                boxSizing: 'border-box',
              }}
            >
              <TimelineHeader
                timelineHours={timelineHours}
                setTimelineHours={setTimelineHours}
              />
            </Box>
            {isLoading ? (
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  padding: '1em',
                  borderRadius: '1em',
                  overflow: 'auto',
                }}
              >
                <LinearProgress />
              </Box>
            ) : error ? (
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  padding: '1em',
                  borderRadius: '1em',
                  overflow: 'auto',
                }}
              >
                {localizeError(t, ensureError(error))}
              </Box>
            ) : hasDevices ? (
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'stretch',
                  flexGrow: '1',
                  ...TimelineMaxWidths(theme),
                  height: '100%',
                  padding: `${PRINTER_PANEL_PADDING}px ${PRINTER_PANEL_PADDING}px 0px ${PRINTER_PANEL_PADDING}px`,
                  gap: `${PRINTER_PANEL_PADDING}px`,
                  boxSizing: 'border-box',
                  overflowY: 'hidden',
                  justifyContent: 'flex-end',
                }}
              >
                <Box
                  component="div"
                  sx={{
                    overflowX: 'auto',
                    overflowY: 'auto',
                    boxSizing: 'content-box',
                    height: '100%',
                    width: '100%',
                  }}
                  onScroll={handleScrollTimeline}
                  ref={scrollRef}
                >
                  <Box
                    component="div"
                    sx={{
                      width: `${hoursViewWidth}px`,
                      position: 'relative',
                      height: `${timelineHeight}px`,
                    }}
                  >
                    {/* Timeline */}
                    <Box
                      component="div"
                      sx={{
                        position: 'absolute',
                        width: `100%`,
                        height: `${timelineHeight}px`,
                        paddingLeft: `${PRINTER_PANEL_PADDING}px`,
                        boxSizing: 'border-box',
                      }}
                    >
                      {/* Hour Markers (Text) */}
                      <Box
                        component="div"
                        sx={{
                          display: 'flex',
                          flexGrow: '1',
                          height: '28px',
                          alignItems: 'stretch',
                          top: '0',
                          position: 'sticky',
                          marginTop: '-1.5em',
                          backgroundColor: theme.surface.low,
                          zIndex: '1',
                          width: 'max-content',
                        }}
                      >
                        {/* 20 minutes worth of additional left padding */}
                        <Box
                          component="div"
                          sx={{
                            height: '100%',
                            minWidth:
                              timelineWidth > 0
                                ? `${hoursViewWidth / timelineHours / 3}px`
                                : '1px',
                          }}
                        />
                        {Array.from(
                          {
                            length: Math.max(
                              timelineHours + 1,
                              maxTimelineHours,
                            ),
                          },
                          (_, i) => (
                            <TimelineHourMarker
                              key={i}
                              index={i}
                              startTime={startTime}
                              timelineWidth={timelineWidth}
                              timelineHours={timelineHours}
                              hoursViewWidth={hoursViewWidth}
                              isHoursText
                            />
                          ),
                        )}
                      </Box>
                      {/* Heading Hour Markers (Dividers) */}
                      <Box
                        component="div"
                        sx={{
                          display: 'flex',
                          flexGrow: '1',
                          height: `${FADE_HEIGHT}px`,
                          alignItems: 'stretch',
                          top: '0',
                          position: 'sticky',
                          marginTop: '-1.5em',
                          zIndex: '10',
                          mixBlendMode: 'hard-light',
                          '&::after': {
                            position: 'absolute',
                            top: '24px',
                            content: '""',
                            height: '100%',
                            width: '100%',
                            background:
                              'linear-gradient(transparent 60%, gray)',
                            pointerEvents: 'none',
                          },
                        }}
                      >
                        {/* 20 minutes worth of additional left padding */}
                        <Box
                          component="div"
                          sx={{
                            height: '100%',
                            minWidth:
                              timelineWidth > 0
                                ? `${hoursViewWidth / timelineHours / 3}px`
                                : '1px',
                          }}
                        />
                        {Array.from(
                          {
                            length: Math.max(
                              timelineHours + 1,
                              maxTimelineHours,
                            ),
                          },
                          (_, i) => (
                            <TimelineHourMarker
                              key={i}
                              index={i}
                              startTime={startTime}
                              timelineWidth={timelineWidth}
                              timelineHours={timelineHours}
                              hoursViewWidth={hoursViewWidth}
                            />
                          ),
                        )}
                      </Box>
                      {/* Hour Markers (Dividers) */}
                      <Box
                        component="div"
                        sx={{
                          display: 'flex',
                          flexGrow: '1',
                          height: `100%`,
                          alignItems: 'stretch',
                          top: '0',
                          position: 'sticky',
                          marginTop: '-1.5em',
                        }}
                      >
                        {/* 20 minutes worth of additional left padding */}
                        <Box
                          component="div"
                          sx={{
                            height: '100%',
                            minWidth:
                              timelineWidth > 0
                                ? `${hoursViewWidth / timelineHours / 3}px`
                                : '1px',
                          }}
                        />
                        {Array.from(
                          {
                            length: Math.max(
                              timelineHours + 1,
                              maxTimelineHours,
                            ),
                          },
                          (_, i) => (
                            <TimelineHourMarker
                              key={i}
                              index={i}
                              startTime={startTime}
                              timelineWidth={timelineWidth}
                              timelineHours={timelineHours}
                              hoursViewWidth={hoursViewWidth}
                            />
                          ),
                        )}
                      </Box>
                    </Box>
                    <Box
                      component="div"
                      sx={{
                        position: 'fixed',
                        height: `${FADE_HEIGHT}px`,
                        width: `${hoursViewWidth}px`,
                        marginTop: '28px',
                        background:
                          'linear-gradient(180deg, rgba(250,250,250,1) 40%, rgba(250,250,250,0) 100%)',
                        zIndex: '2',
                      }}
                    ></Box>
                    {/* Timeline Content */}
                    <Box
                      component="div"
                      sx={{
                        position: 'absolute',
                        width: `${hoursViewWidth + MIN_HOUR_WIDTH * 2}px`,
                        height: '100%',
                        marginTop: '1.5em',
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          gap: '8px',
                          width: 'max-content',
                          paddingTop: `${PRINTER_PANEL_PADDING}px`,
                          boxSizing: 'border-box',
                        }}
                      >
                        {/* Full width row for each printer */}
                        {hasDevices && (
                          <TimelineRows
                            hoursViewWidth={hoursViewWidth}
                            timelineHours={timelineHours}
                          />
                        )}
                      </Box>
                    </Box>
                    {/* Time Cursor */}
                    <Box
                      component="div"
                      sx={{
                        display: 'flex',
                        alignItems: 'stretch',
                        position: 'absolute',
                        zIndex: '3',
                        height: '100%',
                        paddingTop: '1.5em',
                        marginTop: '-1.5em',
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          position: 'absolute',
                          left: `${((hoursViewWidth / timelineHours) * (currentTime - startTime.valueOf())) / 60 / 60 / 1000}px`,
                          overflow: 'visible',
                          width: '4px',
                          height: '100%',
                          backgroundColor: '#000000',
                          border: '1px solid white',
                          boxSizing: 'border-box',
                        }}
                      >
                        <Box
                          component="div"
                          sx={{
                            width: '12px',
                            height: '12px',
                            borderRadius: '6px',
                            backgroundColor: '#000000',
                            border: '1px solid white',
                            top: '0px',
                            marginLeft: '-5px',
                            position: 'sticky',
                          }}
                        />
                      </Box>
                    </Box>
                    {/* Left Fade Gradient */}
                    <Box
                      component="div"
                      sx={{
                        position: 'fixed',
                        width: `16px`,
                        height: `${timelineHeight}px`,
                        background:
                          'linear-gradient(90deg, rgba(250,250,250,1) 0%, rgba(250,250,250,0) 100%)',
                        zIndex: '4',
                      }}
                    ></Box>
                  </Box>
                </Box>
                <Box
                  component="div"
                  id="timeline-printers"
                  sx={{
                    width: `${PRINTER_PANEL_WIDTH}px`,
                    flexShrink: '0',
                    boxSizing: 'border-box',
                    height: 'max-content',
                    minHeight: '100%',
                    paddingTop: `${PRINTER_PANEL_PADDING}px`,
                    marginTop: '1.5em',
                    overflow: 'hidden',
                    paddingLeft: '2px',
                    paddingRight: '2px',
                    zIndex: '5',
                  }}
                  onWheel={(e: WheelEvent<HTMLDivElement>) => {
                    if (scrollRef.current)
                      scrollRef.current.scrollBy(e.deltaX, e.deltaY)
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      position: 'fixed',
                      paddingTop: `${PRINTER_PANEL_PADDING}px`,
                      height: `${FADE_HEIGHT}px`,
                      width: `${PRINTER_PANEL_WIDTH}px`,
                      marginTop: `-${PRINTER_PANEL_PADDING}px`,
                      marginLeft: '-2px',
                      background:
                        'linear-gradient(180deg, rgba(250,250,250,1) 40%, rgba(250,250,250,0) 100%)',
                      zIndex: '7',
                    }}
                  ></Box>
                  <Box
                    component="div"
                    ref={(node: HTMLDivElement | null) => {
                      printersRef.current = node // Set the ref value manually
                      handleSetPrintersHeight(node) // Call the height-setting function
                    }}
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignContent: 'center',
                      justifyContent: 'flex-start',
                      gap: '0.5em',
                    }}
                  >
                    <TimelinePrinters />
                  </Box>
                </Box>
              </Box>
            ) : (
              <EmptyState
                image={'/images/no-devices.svg'}
                title={t('devices:message.devicesPageInfo')}
                message={t('devices:message.addDevices')}
                button={
                  <Button
                    color="primary"
                    href={enrolDeviceLink}
                    target="_blank"
                    sx={{
                      mt: '1.5em',
                    }}
                  >
                    {t('button.add')}
                  </Button>
                }
              />
            )}
          </Box>
        </Box>
      ) : featureFlagTimeout || allowDevicesUi === false ? (
        <UnderConstruction />
      ) : (
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
    </Dashboard>
  )
}

export { Timeline }
export const TIMELINE_TABLE_STATE_NAME = 'timelineTableState'
