import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getThumbnailFromIndexedDB,
  saveDataToIndexedDB,
} from '../../utils/indexeddb'
import { PaginatedPartReadPublic } from '../clientApi'

interface ThumbnailsCacheState {
  [id: string]: { value: boolean }
}

const initialState: ThumbnailsCacheState = {}

const cachePartThumbnails = createAsyncThunk(
  'thumbnailsCache/cachePartThumbnails',
  async (
    partsData: PaginatedPartReadPublic | undefined,
    { dispatch, getState },
  ) => {
    if (!partsData?.content) {
      return
    }
    // Cache thumbnails
    for (const part of partsData.content) {
      const partId = part.id.toString()

      // Check if the thumbnail is already being cached
      const isCaching = (
        getState() as { thumbnailsCache: ThumbnailsCacheState }
      ).thumbnailsCache[partId]?.value
      if (isCaching) {
        continue
      }

      // Set part as being cached
      dispatch(setThumbnailCaching({ id: partId, value: true }))

      const { id, status, thumbnail_presigned_url: thumbnailURL } = part

      if (!thumbnailURL) {
        dispatch(setThumbnailCaching({ id: partId, value: false }))
        continue
      }

      const exists = await getThumbnailFromIndexedDB(id.toString())
      if (exists !== null) {
        dispatch(setThumbnailCaching({ id: partId, value: false }))
        continue
      }

      const response = await fetch(thumbnailURL)
      if (!response.ok) {
        dispatch(setThumbnailCaching({ id: partId, value: false }))
        continue
      }

      const thumbnailBlob = await response.blob()

      await saveDataToIndexedDB(id.toString(), undefined, {
        dataStatus: status,
        data: thumbnailBlob,
        timestamp: Date.now(),
      })

      dispatch(setThumbnailCaching({ id: partId, value: false }))
    }
  },
)

const thumbnailsCacheSlice = createSlice({
  name: 'thumbnailsCache',
  initialState,
  reducers: {
    setThumbnailCaching(
      state,
      action: PayloadAction<{ id: string; value: boolean }>,
    ) {
      const { id, value } = action.payload
      state[id] = { value }
    },
  },
  selectors: {
    selectIsThumbnailCaching(state, id: string) {
      return state[id]?.value === true
    },
  },
})

export const { setThumbnailCaching } = thumbnailsCacheSlice.actions
export const { selectIsThumbnailCaching } = thumbnailsCacheSlice.selectors
export { cachePartThumbnails }
export default thumbnailsCacheSlice.reducer
