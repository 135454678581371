import { Box, Drawer, Theme, useMediaQuery, useTheme } from '@mui/material'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import {
  DetailsItemType,
  selectDetailsItemType,
  selectDrawerOpen,
  setDrawerOpen,
} from '../../store/slices/detailsDrawerSlice'
import { RootState, store } from '../../store/store'
import { DeviceDetails } from './DeviceDetails/DeviceDetails'
import { EmptyDetails } from './EmptyDetails/EmptyDetails'
import { JobDetails } from './JobDetails/JobDetails'
import { NonFunnelJobDetails } from './NonFunnelJobDetails/NonFunnelJobDetails'
import { PartDetails } from './PartDetails/PartDetails'

interface DrawerContentProps {
  drawerItemType?: DetailsItemType
}

const DrawerContent: FC<DrawerContentProps> = ({ drawerItemType }) => {
  switch (drawerItemType) {
    case 'Device':
      return <DeviceDetails />
    case 'Job':
      return <JobDetails />
    case 'NonFunnelJob':
      return <NonFunnelJobDetails />
    case 'Part':
      return <PartDetails />
    default:
      return <EmptyDetails />
  }
}

export const DetailsDrawer: FC = () => {
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const theme = useTheme()
  const sm_down = useMediaQuery(theme.breakpoints.down('sm'))
  const md_down = useMediaQuery(theme.breakpoints.down('md'))
  const lg_down = useMediaQuery(theme.breakpoints.down('lg'))
  const width =
    basePath === 'devices' || basePath === 'jobs'
      ? md_down
        ? '100%'
        : '30vw'
      : lg_down
        ? '100%'
        : '30vw'

  const drawerOpen = useSelector((state: RootState) => selectDrawerOpen(state))

  const drawerItemType = useSelector((state: RootState) =>
    selectDetailsItemType(state, basePath, selectedTab),
  )

  if (!drawerOpen) return <></>

  return sm_down ? (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => store.dispatch(setDrawerOpen({ value: false }))}
      sx={{
        flexShrink: 0,
        flexGrow: 1,
        [`& .MuiDrawer-paper`]: {
          boxSizing: 'border-box',
          marginTop: ['56px', 'unset'],
          width: ['100%', 'unset'],
          maxHeight: 'calc(100vh - 56px)',
        },
        zIndex: (theme) => [theme.zIndex.drawer - 1, theme.zIndex.drawer + 1],
      }}
      elevation={2}
    >
      <Box
        component="div"
        sx={{
          padding: '0px',
          width: ['100%'],
          height: 'calc(100vh - 56px)',
          maxHeight: 'calc(100vh - 56px)',
        }}
      >
        <DrawerContent drawerItemType={drawerItemType} />
      </Box>
    </Drawer>
  ) : (
    <Box
      component="div"
      sx={{
        flexShrink: 0,
        flexGrow: 1,
        width: width,
        height: '100%',
        bgcolor: (theme: Theme) => theme.surface.low,
        padding: '0',
        borderRadius: '1em',
        display: drawerOpen ? 'box' : 'none',
      }}
    >
      <DrawerContent drawerItemType={drawerItemType} />
    </Box>
  )
}
