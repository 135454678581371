import { useTranslation } from 'react-i18next'
import { setDrawerOpen } from '../../../store/slices/detailsDrawerSlice'
import { store } from '../../../store/store'
import { ViewportContainer } from '../../Viewport/Viewport'
import { ExtendedDetails } from '../ExtendedDetails/ExtendedDetails'

export const EmptyDetails = () => {
  const { t } = useTranslation('jobs')
  return (
    <ExtendedDetails
      loading={false}
      title={
        <>
          {t('common:label.itemSelected', {
            count: 0,
          })}
        </>
      }
      close={() => store.dispatch(setDrawerOpen({ value: false }))}
      banner={
        <>
          <ViewportContainer sx={{ height: '100%' }}>
            <img
              id="inspector-no-parts-img"
              alt=""
              src="/images/inspectorNoParts.svg"
            />
          </ViewportContainer>
        </>
      }
    />
  )
}
