import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { ModelFileTypes } from '../../typed/ModelFileTypes'
import { getModelFileTypeFromS3URL } from '../../utils/getModelFileType'
import {
  checkModelInIndexedDB,
  getModelFromIndexedDB,
  saveDataToIndexedDB,
} from '../../utils/indexeddb'
import { clientApi } from '../clientApi'

// Helper function to fetch model data with download progress
const fetchModelWithProgress = async (
  dispatch: any,
  presignedUrl: string,
  id: string,
) => {
  return new Promise<{ data: ArrayBuffer; type: ModelFileTypes }>(
    (resolve, reject) => {
      axios
        .get(presignedUrl, {
          responseType: 'arraybuffer',
          onDownloadProgress: (progressEvent) => {
            if (progressEvent.total !== undefined) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              )
              dispatch(setModelDownloadProgress({ progress, id }))
            }
          },
        })
        .then((response: AxiosResponse<ArrayBuffer>) => {
          const modelType = getModelFileTypeFromS3URL(presignedUrl)
          const modelData = {
            data: response.data,
            type: modelType as ModelFileTypes,
          }
          resolve(modelData)
        })
        .catch((error: Error) => {
          reject(error.message)
        })
    },
  )
}

interface ViewportModelData {
  modelDownloadProgress: number | undefined
  modelViewportError: string | undefined
  loading: boolean
  loaded: boolean
}

// Define a type for the state
interface ViewportModelState {
  data: {
    [index: string]: ViewportModelData
  }
}

// Define initial state
const initialState: ViewportModelState = {
  data: {},
}

// Slice definition
const viewportModelSlice = createSlice({
  name: 'viewportModel',
  initialState,
  reducers: {
    clearModel: (state, action: PayloadAction<{ id: string }>) => {
      if (!state.data[action.payload.id]) {
        return
      }
      state.data[action.payload.id] = {
        modelDownloadProgress: undefined,
        modelViewportError: undefined,
        loading: false,
        loaded: false,
      }
    },
    setModelDownloadProgress: (
      state,
      action: PayloadAction<{ progress: number; id: string }>,
    ) => {
      state.data[action.payload.id] = {
        modelDownloadProgress: action.payload.progress,
        modelViewportError: undefined,
        loading: action.payload.progress !== 100,
        loaded: false,
      }
    },
    setModelDownloadError: (
      state,
      action: PayloadAction<{ error: string; id: string }>,
    ) => {
      state.data[action.payload.id] = {
        modelDownloadProgress: undefined,
        modelViewportError: action.payload.error,
        loading: false,
        loaded: false,
      }
    },
    setModelLoaded(
      state,
      action: PayloadAction<{ id: string; loaded: boolean }>,
    ) {
      state.data[action.payload.id] = {
        ...state.data[action.payload.id],
        loading: state.data[action.payload.id]?.loaded
          ? false
          : state.data[action.payload.id]?.loaded,
        loaded: action.payload.loaded,
      }
    },
  },
  selectors: {
    selectModelDownloadProgress: (state, id: string): number | undefined => {
      return state.data[id]?.modelDownloadProgress
    },
    selectModelDownloadError: (state, id: string): string | undefined => {
      return state.data[id]?.modelViewportError
    },
    selectModelDownloadLoading: (state, id: string): boolean | undefined => {
      return state.data[id]?.loading
    },
    selectModelLoaded(state, id: string): boolean | undefined {
      return state.data[id]?.loaded
    },
  },
})

export const availableViewportModel = createAsyncThunk<
  boolean, // return type
  {
    partId?: number
    partStatus?: number
    jobId?: number
  }, // argument type
  { rejectValue: string }
>('viewportModel/fetchModel', async (params, { dispatch, getState }) => {
  const { partId, partStatus, jobId } = params

  const indexedDBPrefx = partId === undefined ? 'JOB' : 'PART'

  // Check for invalid IDs
  const validId = partId ?? jobId
  if (validId === undefined) {
    return false
  }

  if (partId !== undefined && partStatus === undefined) {
    dispatch(
      setModelDownloadError({
        error: 'Invalid Part ID or status for loading model',
        id: validId.toString(),
      }),
    )
    return false
  }
  // Check that this is not already in progress
  const { data } = (getState() as { viewportModel: ViewportModelState })
    .viewportModel

  if (
    data[validId]?.modelDownloadProgress !== undefined &&
    data[validId]?.loading
  ) {
    return false
  }

  // Mark model as loading
  dispatch(setModelDownloadProgress({ progress: 0, id: validId.toString() }))

  try {
    let modelExists = undefined

    if (partStatus !== undefined) {
      // We need to retrieve the model data to check if the part
      // status has changed
      modelExists = await getModelFromIndexedDB(
        validId.toString(),
        indexedDBPrefx,
        partStatus,
      )
    } else {
      // For jobs, we only need to check if the key exists
      modelExists = await checkModelInIndexedDB(
        validId.toString(),
        indexedDBPrefx,
      )
    }

    // If the model is not in IndexedDB
    if (!modelExists) {
      let presignedUrl = undefined
      if (partId !== undefined) {
        const { data: downloadData } = await dispatch(
          clientApi.endpoints.downloadPartApiV1PartsPartIdUploadedGet.initiate({
            partId: partId as number,
          }),
        )
        presignedUrl = downloadData?.presigned_url
      } else if (jobId !== undefined) {
        const { data: downloadData } = await dispatch(
          clientApi.endpoints.downloadJobPreviewApiV1JobsJobIdDownloadPreviewGet.initiate(
            {
              jobId: jobId as number,
            },
          ),
        )
        presignedUrl = downloadData?.presigned_url
      }

      if (!presignedUrl) {
        dispatch(
          setModelDownloadError({
            error: 'Failed to retrieved presigned URL',
            id: validId.toString(),
          }),
        )
        return false
      }
      const modelData = await fetchModelWithProgress(
        dispatch,
        presignedUrl,
        validId.toString(),
      )

      // Save to IndexedDB after fetching, including validStatus
      await saveDataToIndexedDB(`${indexedDBPrefx}-${validId.toString()}`, {
        dataStatus: partStatus ?? -1,
        data: modelData,
        timestamp: Date.now(),
      })
    }

    // Mark model as loaded
    dispatch(setModelLoaded({ id: validId.toString(), loaded: true }))
  } catch (error) {
    dispatch(
      setModelDownloadError({
        error: 'Failed to fetch model data',
        id: validId.toString(),
      }),
    )
    return false
  }
  return true
})

// Actions and reducers export
export const {
  clearModel,
  setModelDownloadProgress,
  setModelDownloadError,
  setModelLoaded,
} = viewportModelSlice.actions
export const {
  selectModelDownloadProgress,
  selectModelDownloadError,
  selectModelDownloadLoading,
  selectModelLoaded,
} = viewportModelSlice.selectors
export default viewportModelSlice.reducer
