import { createSelector } from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import {
  clientApi,
  DeviceStatusPaginatedListRead,
  DeviceStatusRead,
} from '../../../store/clientApi'
import {
  setDetailsItem,
  setDrawerOpen,
} from '../../../store/slices/detailsDrawerSlice'
import {
  selectRtkData,
  setTableChecked,
} from '../../../store/slices/tableSlice'
import { RootState, store } from '../../../store/store'
import { TIMELINE_TABLE_STATE_NAME } from '../Timeline'
import { TimelinePrinter } from '../TimelinePrinter/TimelinePrinter'

export const TimelinePrinters = () => {
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]

  const selectThingNamesFromDevices = useMemo(() => {
    const emptyArray: DeviceStatusRead[] = []
    return createSelector(
      [(res?: DeviceStatusPaginatedListRead) => res?.content ?? emptyArray],
      (content) => content.map((device) => device.thing_name),
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [])

  const deviceThingNames = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return selectThingNamesFromDevices(query?.data)
  })

  return (
    <>
      {deviceThingNames.map((thingName, index) => {
        return (
          <TimelinePrinter
            thingName={thingName}
            key={`build-${thingName}-${index}`}
            onClick={() => {
              store.dispatch(
                setDrawerOpen({
                  value: true,
                }),
              )
              store.dispatch(
                setTableChecked({
                  name: TIMELINE_TABLE_STATE_NAME,
                  checked: { [thingName]: true },
                  lastChecked: thingName,
                }),
              )
              store.dispatch(
                setDetailsItem({
                  page: basePath,
                  tab: selectedTab,
                  itemType: 'Device',
                  selectedId: thingName,
                }),
              )
            }}
          />
        )
      })}
    </>
  )
}
