import { Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgressStatus } from '../../store/clientApi'
import {
  acceptPartForPrinting,
  approvePrintJob,
  nestPrintJob,
  orientatePart,
  partIdentification,
  PartProgressStep,
  partProgressToProgressStep,
  print,
  sendToPrinter,
} from '../../utils/partChecklistStatusMaping'
import { partProgressStatusToTranslatedKey } from '../../utils/partProgressStatusToTranslatedKey'
import { PartProgressStatusText } from '../PartStatus/PartStatus'
import { Checklist, ChecklistItemStatus } from './Checklist'

interface PartProgressChecklistProps {
  status?: ProgressStatus
}

/**
 * @param status the current progress status of the part
 * @returns A Checklist of the part's current and remaining steps
 */
export const PartProgressChecklist: FC<PartProgressChecklistProps> = ({
  status,
}) => {
  const { t } = useTranslation('parts') // Assuming translations are in the 'parts' namespace

  if (!status)
    return (
      <Typography fontSize={'0.9em'} variant="h1">
        {t('common:loading.unavailable')}
      </Typography>
    )
  const currentStep = partProgressToProgressStep(status)

  if (currentStep === PartProgressStep['rejected']) {
    return (
      <Checklist
        items={[
          {
            text: t(`status.rejected`), // Use translated first enum key
            status: 'FAILED' as const,
          },
        ]}
      />
    )
  }

  const getLastEnumKey = (enumObj: object, stepName: string) => {
    const enumKeys = Object.keys(enumObj).filter((key) => isNaN(Number(key))) // Filter out numeric keys
    const lastKey = enumKeys[enumKeys.length - 1]
    return t(`status.${stepName}.${lastKey}`)
  }

  const getFirstEnumKey = (enumObj: object, stepName: string) => {
    const enumKeys = Object.keys(enumObj).filter((key) => isNaN(Number(key))) // Filter out numeric keys
    const firstKey = enumKeys[0]
    return t(`status.${stepName}.${firstKey}`)
  }

  const steps = [
    { key: 'partIdentification', enumObj: partIdentification },
    { key: 'acceptPartForPrinting', enumObj: acceptPartForPrinting },
    { key: 'orientatePart', enumObj: orientatePart },
    { key: 'nestPrintJob', enumObj: nestPrintJob },
    { key: 'approvePrintJob', enumObj: approvePrintJob },
    { key: 'sendToPrinter', enumObj: sendToPrinter },
    { key: 'print', enumObj: print },
  ]

  // Array for storing step values
  const stepValues = Array.from({ length: 7 }, (_, i) => {
    const getCurrentStepStatus = (): ChecklistItemStatus => {
      const currentStatusKey = partProgressStatusToTranslatedKey(status)
      const isFailedStep = currentStatusKey.toLowerCase().includes('failed')
      if (isFailedStep) return 'FAILED'

      const requireActionStatuses = [
        'confirmPartType',
        'acceptPartForPrinting',
        'approvePrintJob',
        'printJobPendingApproval',
      ]

      const finalSuccessStatuses = ['printed', 'printJobDownloaded']

      const progressStatus = currentStatusKey.split('.').pop() ?? ''
      if (finalSuccessStatuses.includes(progressStatus)) return 'SUCCESS'

      return requireActionStatuses.includes(progressStatus)
        ? 'PENDING APPROVAL'
        : 'IN PROGRESS'
    }

    if (i < currentStep) {
      // Step is completed, return last enum value and its translated string
      return {
        text: getLastEnumKey(steps[i].enumObj, steps[i].key), // last key is completed value for step
        status: 'SUCCESS' as const,
      }
    } else if (i === currentStep) {
      // Step is in progress, return current step text
      return {
        text: <PartProgressStatusText status={status} />,
        status: getCurrentStepStatus(),
      }
    } else {
      // Step not started yet, return first enum value and its translated string
      return {
        text: getFirstEnumKey(steps[i].enumObj, steps[i].key), // Use translated first enum key
        status: 'NOT STARTED' as const,
      }
    }
  })

  return <Checklist items={stepValues} />
}
