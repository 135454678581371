import { Skeleton } from '@mui/material'
import React, { FC } from 'react'

interface ThumbnailProps {
  imageUrl?: string
  fallback?: React.ReactNode
  loading: boolean
}

export const Thumbnail: FC<ThumbnailProps> = ({
  imageUrl,
  loading,
  fallback,
}) => {
  if (imageUrl !== undefined) {
    return (
      <img
        alt=""
        loading="lazy"
        src={imageUrl}
        style={{ width: '100%', height: '40px', objectFit: 'contain' }}
      />
    )
  }

  if (loading) {
    return <Skeleton variant="circular" width={28} height={28} />
  }

  if (fallback !== undefined) {
    return <>{fallback}</>
  }

  return <></>
}
