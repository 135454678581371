import { Divider } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { TIMELINE_TABLE_STATE_NAME } from '../../../pages/Timeline'
import { clientApi } from '../../../store/clientApi'
import {
  invalidateDetailsItem,
  selectDetailsItemId,
  setDrawerOpen,
} from '../../../store/slices/detailsDrawerSlice'
import { selectRtkData } from '../../../store/slices/tableSlice'
import { RootState, store } from '../../../store/store'
import { humanizeDurationHoursMinutes } from '../../../utils/shortHumanizeDuration'
import { ViewportContainer } from '../../Viewport/Viewport'
import {
  DetailsGrid,
  ExtendedDetails,
} from '../ExtendedDetails/ExtendedDetails'

export const NonFunnelJobDetails = () => {
  const { t } = useTranslation('jobs')
  const UNAVAILABLE = t('common:loading.unavailable')
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]

  // Details id = "<Device_Serial> <Job>"
  const selectedDeviceAndJob = useSelector((state: RootState) =>
    selectDetailsItemId(state, basePath, selectedTab),
  )
  // Split on first space
  const selectedDeviceAndJobArray = selectedDeviceAndJob?.split(/ (.*)/s)
  const selectedDeviceName = selectedDeviceAndJobArray?.[0] ?? undefined
  const selectedJobId = selectedDeviceAndJobArray?.[1] ?? undefined

  const currDevice = useSelector((state: RootState) =>
    selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )?.data?.content?.find(
      (device) => device.thing_name === selectedDeviceName,
    ),
  )

  const queued_build_names = useMemo(() => {
    return (currDevice?.status?.queued_build_names ?? []) as string[]
  }, [currDevice])

  const queued_build_times = useMemo(() => {
    return (currDevice?.status?.queued_build_times ?? []) as number[]
  }, [currDevice])

  const jobEstimatedPrintTime =
    selectedJobId &&
    queued_build_times?.[queued_build_names?.indexOf(selectedJobId)]

  const jobInformation = [
    {
      name: t('label.estimatedPrintTime'),
      value: jobEstimatedPrintTime
        ? humanizeDurationHoursMinutes(jobEstimatedPrintTime * 1000)
        : t('common:loading.unavailable'),
    },
    {
      name: t('label.source'),
      value: t('label.external'),
    },
    {
      name: t('label.targetDevice'),
      value: currDevice?.status?.name ?? UNAVAILABLE,
    },
  ]

  return (
    <ExtendedDetails
      loading={false}
      title={<>{selectedJobId}</>}
      close={() => {
        store.dispatch(setDrawerOpen({ value: false }))
        store.dispatch(
          invalidateDetailsItem({ page: basePath, tab: selectedTab }),
        )
      }}
      banner={
        <>
          <ViewportContainer>
            <img
              alt=""
              src="/images/noBuildSelected.svg"
              style={{
                padding: '1em',
                boxSizing: 'border-box',
                height: '100%',
              }}
            />
          </ViewportContainer>
          <Divider />
        </>
      }
      entries={{
        [t('details.jobInformation')]: {
          startOpen: true,
          content: <DetailsGrid details={jobInformation} />,
        },
      }}
      footer={<></>}
    />
  )
}
