import { CircularProgress, Typography } from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { ensureError, localizeError } from '../../helpers'
import {
  clientApi,
  JobReadPublic,
  JobStatus,
  useAcceptJobApiV1JobsJobIdAcceptPutMutation,
  useDownloadJobApiV1JobsJobIdUploadGetMutation,
  useGetDeviceWithStatusGetMutation,
  useMoveJobOfflineApiV1JobsJobIdOfflinePutMutation,
  useRequeuePartsInJobMutation,
} from '../../store/clientApi'
import { invalidateDrawer } from '../../store/slices/detailsDrawerSlice'
import {
  invalidate,
  selectCheckedIds,
  selectRtkData,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { Button } from '../Button/Button'
import { DialogBox } from '../DialogBox'
import {
  CloseSvg,
  DoneSvg,
  DownloadSvg,
  ReQueueSvg,
  SendToMachineSvg,
} from '../Icon/Icon'
import { JOB_TABLE_STATE_NAME } from '../JobsList/JobsList'

const invalidateTable = () => {
  store.dispatch(invalidate(JOB_TABLE_STATE_NAME))
  store.dispatch(invalidateDrawer())
}

const emptyArray: JobReadPublic[] = []

const selectSelectedJobs = createSelector(
  [
    (state: RootState): JobReadPublic[] =>
      selectRtkData(
        state,
        JOB_TABLE_STATE_NAME,
        clientApi.endpoints.getJobsApiV1JobsGet.select,
      )?.data?.content ?? emptyArray,
    (state: RootState) => selectCheckedIds(state, JOB_TABLE_STATE_NAME),
  ],
  (content, checkedIds) => {
    return checkedIds
      ? (checkedIds
          .map((index) => content.find((job) => job.id.toString() === index))
          .filter((job) => job !== undefined) as JobReadPublic[])
      : emptyArray
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
)

const MoveOfflineButton = ({ inDrawer }: { inDrawer?: boolean }) => {
  const { t } = useTranslation('jobs')
  const [rejectOpen, setRejectOpen] = useState(false)
  const [offline] = useMoveJobOfflineApiV1JobsJobIdOfflinePutMutation()
  const checkedIds = useSelector((state: RootState) =>
    selectCheckedIds(state, JOB_TABLE_STATE_NAME),
  )

  const canMoveOffline = useSelector((state: RootState) =>
    selectSelectedJobs(state).every(
      (job) => job.status === JobStatus.TO_ACCEPT,
    ),
  )

  const moveOffline = async () => {
    const loadingId = toast.loading(t('common:label.preparingDownload'))
    invalidateTable()
    let successCount = 0

    // Create an array of promises for all jobs
    const promises = checkedIds.map(async (job) => {
      try {
        const download = await offline({ jobId: Number(job) }).unwrap()

        const link = document.createElement('a')
        link.href = download.presigned_url
        link.download = `${job}.zip`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        toast.custom(
          <Trans
            t={t}
            i18nKey={'message.reworkOffline'}
            components={{
              bold: <strong style={{ fontWeight: 'bold' }} />,
            }}
            values={{
              id: job,
            }}
          />,
          {
            icon: <DownloadSvg color="inherit" />,
          },
        )
        successCount++
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    })

    // Wait for all promises to settle (fulfilled or rejected)
    await Promise.allSettled(promises)

    toast.dismiss(loadingId)

    if (successCount > 0) {
      toast.success(
        t('common:label.downloadJobsSuccess', { count: successCount }),
      )
    }
  }

  return canMoveOffline ? (
    <>
      <Button
        color="error"
        startIcon={<CloseSvg color="inherit" />}
        onClick={() => setRejectOpen(true)}
        short={inDrawer}
      >
        {t('button.moveOffline')}
      </Button>
      <DialogBox
        title={t('title.moveOffline')}
        message={
          <>
            <Typography>
              {t('message.moveOffline', { count: checkedIds.length })}
            </Typography>
          </>
        }
        open={rejectOpen}
        setOpen={setRejectOpen}
        args={undefined}
        onClose={(approve) => {
          if (approve) moveOffline()
        }}
        confirmText={t('button.download')}
        confirmColor={'error'}
        declineText={t('button.cancel')}
        declineColor={'secondary'}
        ignoreClosed
      ></DialogBox>
    </>
  ) : null
}

const AcceptButton = ({ inDrawer }: { inDrawer?: boolean }) => {
  const { t } = useTranslation('jobs')
  const [accept] = useAcceptJobApiV1JobsJobIdAcceptPutMutation()
  const [getDevice] = useGetDeviceWithStatusGetMutation()
  const checkedIds = useSelector((state: RootState) =>
    selectCheckedIds(state, JOB_TABLE_STATE_NAME),
  )

  const canAccept = useSelector((state: RootState) =>
    selectSelectedJobs(state).every(
      (job) => job.status === JobStatus.TO_ACCEPT,
    ),
  )

  const selectSerialNumbersFromJobs = useMemo(() => {
    const emptyArray: JobReadPublic[] = []
    return createSelector(
      [(res?: JobReadPublic[]) => res ?? emptyArray],
      (content) => content.map((job) => job.printer_serial),
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [])

  const devices = useSelector((state: RootState) =>
    selectSerialNumbersFromJobs(selectSelectedJobs(state)),
  )

  const acceptJobs = async () => {
    const loadingId = toast.loading(t('common:label.acceptingJobs'))
    invalidateTable()
    let successCount = 0

    // Create an array of promises for all jobs
    const promises = checkedIds.map(async (job, i) => {
      let device: string | undefined = undefined

      try {
        device =
          (await getDevice({ serialNumber: devices[i] }).unwrap()).status
            ?.name ?? undefined
      } catch {
        //
      }

      toast.custom(
        <Trans
          t={t}
          i18nKey={
            'message.submittingJob' + (device !== undefined ? '_device' : '')
          }
          components={{
            bold: <strong style={{ fontWeight: 'bold' }} />,
          }}
          values={{
            device: device,
            id: job,
          }}
        />,
        {
          icon: <SendToMachineSvg color="inherit" />,
        },
      )

      try {
        await accept({ jobId: Number(job) }).unwrap()
        successCount++
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    })

    // Wait for all promises to settle (fulfilled or rejected)
    await Promise.allSettled(promises)
    toast.dismiss(loadingId)

    if (successCount > 0) {
      toast.success(
        t('common:label.acceptedJobsSuccess', { count: successCount }),
      )
    }
  }

  return canAccept ? (
    <Button
      startIcon={<DoneSvg color="inherit" />}
      onClick={acceptJobs}
      short={inDrawer}
    >
      {t('common:button.accept')}
    </Button>
  ) : null
}

const RequeuePartsButton = ({ inDrawer }: { inDrawer?: boolean }) => {
  const { t } = useTranslation('jobs')
  const [requeue] = useRequeuePartsInJobMutation()
  const [requeueOpen, setRequeueOpen] = useState(false)
  const checkedIds = useSelector((state: RootState) =>
    selectCheckedIds(state, JOB_TABLE_STATE_NAME),
  )

  const canRequeue = useSelector((state: RootState) =>
    selectSelectedJobs(state).every(
      (job) =>
        job.status === JobStatus.TO_ACCEPT || job.status === JobStatus.FAILED,
    ),
  )

  const requeueParts = async () => {
    const loadingId = toast.loading(t('label.requeuingParts'))
    invalidateTable()
    let successCount = 0

    // Create an array of promises for all jobs
    const promises = checkedIds.map(async (job) => {
      try {
        await requeue({ jobId: Number(job) }).unwrap()
        successCount++
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    })

    // Wait for all promises to settle (fulfilled or rejected)
    await Promise.allSettled(promises)
    toast.dismiss(loadingId)

    if (successCount > 0) {
      toast.success(t('label.requeuedPartsSuccess', { count: successCount }))
    }
  }

  return canRequeue ? (
    <>
      <Button
        startIcon={<ReQueueSvg color="inherit" />}
        onClick={() => setRequeueOpen(true)}
        short={inDrawer}
      >
        {t('button.requeueParts')}
      </Button>
      <DialogBox
        title={t('title.requeueParts')}
        message={
          <>
            <Typography>{t('message.requeueParts')}</Typography>
          </>
        }
        open={requeueOpen}
        setOpen={setRequeueOpen}
        args={undefined}
        onClose={(approve) => {
          if (approve) requeueParts()
        }}
        confirmText={t('button.requeueParts')}
        confirmColor={'primary'}
        declineText={t('common:button.cancel')}
        declineColor={'secondary'}
        ignoreClosed
      ></DialogBox>
    </>
  ) : null
}

const DownloadButton = ({ inDrawer }: { inDrawer?: boolean }) => {
  const { t } = useTranslation('jobs')
  const [download] = useDownloadJobApiV1JobsJobIdUploadGetMutation()
  const checkedIds = useSelector((state: RootState) =>
    selectCheckedIds(state, JOB_TABLE_STATE_NAME),
  )

  const canDownload = useSelector((state: RootState) =>
    selectSelectedJobs(state).every((job) => job.status >= JobStatus.TO_ACCEPT),
  )

  const downloadJobs = async () => {
    let successCount = 0
    const loadingId = toast.loading(t('common:label.preparingDownload'))
    invalidateTable()

    // Create an array of promises for each job download
    const promises = checkedIds.map(async (job) => {
      try {
        const downloadUrl = await download({ jobId: parseInt(job) }).unwrap()

        const link = document.createElement('a')
        link.href = downloadUrl.presigned_url
        link.download = `${job}.zip`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        successCount++
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    })

    // Wait for all promises to settle (fulfilled or rejected)
    await Promise.allSettled(promises)

    // Dismiss the loading toast once all promises are settled
    toast.dismiss(loadingId)

    if (successCount > 0) {
      toast.success(
        t('common:label.downloadJobsSuccess', { count: successCount }),
      )
    }
  }

  return canDownload ? (
    <Button
      startIcon={<DownloadSvg color="inherit" />}
      onClick={() => downloadJobs()}
      disabled={!canDownload}
      short={inDrawer}
    >
      {t('button.download')}
    </Button>
  ) : null
}

export const Edit = ({ inDrawer }: { inDrawer?: boolean }) => {
  const isSelected = useSelector((state: RootState) => {
    const content =
      selectRtkData(
        state,
        JOB_TABLE_STATE_NAME,
        clientApi.endpoints.getJobsApiV1JobsGet.select,
      )?.data?.content ?? []
    const checkedIds = selectCheckedIds(state, JOB_TABLE_STATE_NAME)
    return (
      (checkedIds ?? [])
        .map((index) => content.find((job) => job.id.toString() === index))
        .filter((job) => job !== undefined).length > 0
    )
  })

  const error = useSelector(
    (state: RootState) =>
      selectRtkData(
        state,
        JOB_TABLE_STATE_NAME,
        clientApi.endpoints.getJobsApiV1JobsGet.select,
      )?.error,
  )
  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      JOB_TABLE_STATE_NAME,
      clientApi.endpoints.getJobsApiV1JobsGet.select,
    )

    return query?.isLoading && query?.isUninitialized
  })

  return error ? (
    <>{null}</>
  ) : isLoading ? (
    <CircularProgress />
  ) : isSelected ? (
    <>
      <MoveOfflineButton inDrawer={inDrawer} />
      <RequeuePartsButton inDrawer={inDrawer} />
      <AcceptButton inDrawer={inDrawer} />
      <DownloadButton inDrawer={inDrawer} />
    </>
  ) : (
    <>{null}</>
  )
}
