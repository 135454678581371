import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface DevicesState {
  scroll: number
  devicesInErrorState: string[]
}

const initialState: DevicesState = {
  scroll: 0,
  devicesInErrorState: [],
} satisfies DevicesState
const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setScroll(state, action: PayloadAction<number>) {
      state.scroll = action.payload
    },
    setDevicesInErrorState(state, action: PayloadAction<string[]>) {
      state.devicesInErrorState = action.payload
    },
  },
  selectors: {
    selectDevicesInErrorState(state): string[] {
      return state.devicesInErrorState
    },
  },
})

export const { setScroll, setDevicesInErrorState } = devicesSlice.actions
export const { selectDevicesInErrorState } = devicesSlice.selectors
export default devicesSlice.reducer
