import {
  Box,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material'
import { TFunction } from 'i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { Vector3 } from 'three'
import { ensureError, localizeError } from '../../../helpers'
import {
  JobStatus,
  PartReadPublic,
  useGetDeviceBuildExtentsApiV1DevicesSerialNumberBuildExtentsGetQuery,
  useGetDeviceStatusApiV1DevicesSerialNumberStatusGetQuery,
  useGetJobApiV1JobsJobIdGetQuery,
  useGetMaterialApiV1MaterialsMaterialIdGetQuery,
  useGetPartsApiV1PartsGetQuery,
} from '../../../store/clientApi'
import { useAppDispatch } from '../../../store/hooks'
import {
  invalidateDetailsItem,
  selectDetailsItemId,
  selectDetailsNumSelected,
  setDetailsItem,
  setDrawerOpen,
} from '../../../store/slices/detailsDrawerSlice'
import { cachePartThumbnails } from '../../../store/slices/thumbnailsCacheSlice'
import {
  availableViewportModel,
  selectModelDownloadError,
  selectModelLoaded,
} from '../../../store/slices/viewportModelSlice'
import { RootState, store } from '../../../store/store'
import { ModelFileTypes } from '../../../typed/ModelFileTypes'
import { rotationDegreesToEuler } from '../../../utils/geometryUtils'
import { getBuildPlatform } from '../../../utils/getBuildPlatform'
import { getModelFromIndexedDB } from '../../../utils/indexeddb'
import { BuildPlatformTypes } from '../../BuildPlatform/BuildPlatform'
import { Edit } from '../../JobEdit/JobEdit'
import { Model } from '../../Model/Model'
import { PartThumbnail } from '../../Thumbnail/PartThumbnail'
import {
  Viewport,
  ViewportContainer,
  ViewportLoading,
} from '../../Viewport/Viewport'
import {
  DetailsGrid,
  ExtendedDetails,
} from '../ExtendedDetails/ExtendedDetails'

const Title = () => {
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const { t } = useTranslation('jobs')
  const numSelected = useSelector((state: RootState) =>
    selectDetailsNumSelected(state, basePath, selectedTab),
  )
  return (
    <>
      {t('common:label.itemSelected', {
        count: numSelected,
      })}
    </>
  )
}

interface JobPartRowProps {
  part: PartReadPublic
}

const JobPartRow: FC<JobPartRowProps> = ({ part }) => {
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const theme = useTheme()

  const handleOnClick = () => {
    store.dispatch(
      setDetailsItem({
        page: basePath,
        tab: selectedTab,
        itemType: 'Part',
        isOverlay: true,
        selectedId: part.id.toString(),
      }),
    )
  }

  return (
    <TableRow
      onClick={handleOnClick}
      sx={{
        '&&:hover': {
          backgroundColor: theme.surface.normal,
        },
        cursor: 'pointer',
      }}
    >
      <TableCell>
        <Box
          component="div"
          sx={{
            display: 'flex',
            gap: '0.5em',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <PartThumbnail PartId={part.id} PartStatus={part.status} />
          {part.name.toString()}
        </Box>
      </TableCell>
    </TableRow>
  )
}

interface JobPartsListProps {
  selectedJobId: string | undefined
}
const JobPartsList: FC<JobPartsListProps> = ({ selectedJobId }) => {
  const { t } = useTranslation('jobs')
  const UNAVAILABLE = t('common:loading.unavailable')
  const dispatch = useAppDispatch()

  const {
    data: partsData,
    isLoading: partsLoading,
    error: partsError,
  } = useGetPartsApiV1PartsGetQuery(
    {
      query: `job_id:${selectedJobId ?? ''}`,
      perPage: 100, // placement is limited to 100 parts
      withThumbnails: true,
    },
    { skip: selectedJobId === undefined },
  )

  useEffect(() => {
    dispatch(cachePartThumbnails(partsData))
  }, [partsData, dispatch])

  return partsError ? (
    <>{null}</>
  ) : partsLoading ? (
    <CircularProgress />
  ) : partsData?.content ? (
    <Table sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
      <TableHead>
        <TableRow>
          <TableCell>{t('label.partName')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {partsData.content.map((part) => (
          <JobPartRow key={`${part.id}-${part.name}`} part={part} />
        ))}
      </TableBody>
    </Table>
  ) : (
    <Table sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
      <TableRow>
        <TableCell>{UNAVAILABLE}</TableCell>
      </TableRow>
    </Table>
  )
}

const JobViewport: FC = () => {
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const [buildPlatformType, setBuildPlatformType] = useState<
    BuildPlatformTypes | undefined
  >(undefined)
  const [modelFile, setModelFile] = useState<
    | {
        modelId: string
        modelData: {
          data: ArrayBuffer
          type: ModelFileTypes
        }
      }
    | undefined
  >()

  const selectedJobId = useSelector((state: RootState) =>
    selectDetailsItemId(state, basePath, selectedTab),
  )

  const modelViewportError = useSelector((state: RootState) =>
    selectModelDownloadError(state, selectedJobId ?? '0'),
  )

  const modelLoaded = useSelector((state: RootState) =>
    selectedJobId ? selectModelLoaded(state, selectedJobId) : false,
  )

  const { selectedJobPrinterSerial, selectedJobStatusPreToAccept, error } =
    useGetJobApiV1JobsJobIdGetQuery(
      {
        jobId: Number(selectedJobId),
      },
      {
        selectFromResult: ({ data, error }) => {
          return {
            selectedJobPrinterSerial: data?.printer_serial,
            selectedJobStatusPreToAccept: (data?.status ?? 0) < 3,
            error,
          }
        },
        skip: selectedJobId === undefined,
      },
    )

  const { data: buildExtents } =
    useGetDeviceBuildExtentsApiV1DevicesSerialNumberBuildExtentsGetQuery(
      {
        serialNumber: selectedJobPrinterSerial ?? '',
      },
      { skip: selectedJobPrinterSerial === undefined },
    )

  const loadModelFile = async () => {
    if (selectedJobId === undefined) return
    if (selectedJobStatusPreToAccept) {
      // Job must be at least TO_ACCEPT
      return
    }
    const modelAvailable = await store.dispatch(
      availableViewportModel({
        jobId: parseInt(selectedJobId),
      }),
    )
    if (modelAvailable) {
      const modelFileData = await getModelFromIndexedDB(selectedJobId, 'JOB')
      if (modelFileData !== null) {
        setModelFile({ modelId: selectedJobId, modelData: modelFileData })
      }
    }
  }

  useEffect(() => {
    if (
      selectedJobId === undefined ||
      selectedJobStatusPreToAccept === undefined
    ) {
      return
    }
    if (modelFile && selectedJobId !== modelFile.modelId) {
      setModelFile(undefined)
    }

    loadModelFile()
  }, [selectedJobStatusPreToAccept, selectedJobId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (modelLoaded && modelFile === undefined) {
      loadModelFile()
    }
  }, [modelLoaded, modelFile]) //eslint-disable-line

  useEffect(() => {
    if (buildExtents) {
      const buildPlatformType = getBuildPlatform('PRO 4K UV385', buildExtents)
      setBuildPlatformType(buildPlatformType)
    }
  }, [buildExtents])

  return (
    <>
      {error || modelViewportError || selectedJobStatusPreToAccept ? (
        <ViewportContainer>
          <img
            alt=""
            src="/images/noBuildSelected.svg"
            style={{
              padding: '1em',
              boxSizing: 'border-box',
              height: '100%',
            }}
          />
        </ViewportContainer>
      ) : modelFile !== undefined ? (
        <Viewport
          modelName={<JobSelectedItemTitle />}
          buildPlatform={buildPlatformType}
          buildExtents={buildExtents}
          maximisedFooter={
            <Box
              component="div"
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                gap: '6px',
              }}
            ></Box>
          }
        >
          {buildExtents && (
            <Model
              file={modelFile.modelData}
              position={new Vector3(-buildExtents.x / 2, 0, buildExtents.y / 2)}
              rotation={rotationDegreesToEuler(-90, 0, 0)}
            />
          )}
        </Viewport>
      ) : (
        <ViewportLoading modelId={selectedJobId ?? '0'} />
      )}
      <Divider />
    </>
  )
}

const JobInformation = () => {
  const { t } = useTranslation('jobs')
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const UNAVAILABLE = t('common:loading.unavailable')

  const selectedJobId = useSelector((state: RootState) =>
    selectDetailsItemId(state, basePath, selectedTab),
  )
  const { selectedJobPrinterSerial } = useGetJobApiV1JobsJobIdGetQuery(
    {
      jobId: Number(selectedJobId),
    },
    {
      selectFromResult: ({ data }) => {
        return {
          selectedJobPrinterSerial: data?.printer_serial,
        }
      },
      skip: selectedJobId === undefined,
    },
  )
  const { data: deviceData } =
    useGetDeviceStatusApiV1DevicesSerialNumberStatusGetQuery(
      {
        serialNumber: selectedJobPrinterSerial ?? '',
      },
      { skip: selectedJobPrinterSerial === undefined },
    )
  const jobInformation = [
    {
      name: t('label.estimatedPrintTime'),
      value: UNAVAILABLE,
    },
    {
      name: t('label.estimatedMaterial'),
      value: UNAVAILABLE,
    },
    {
      name: t('label.targetDevice'),
      value: deviceData?.status?.name ?? UNAVAILABLE,
    },
  ]

  return <DetailsGrid details={jobInformation} />
}

const JobDetailsFooter = () => {
  const { enableJobReview } = useFlags()
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]

  const selectedJobId = useSelector((state: RootState) =>
    selectDetailsItemId(state, basePath, selectedTab),
  )

  const { selectedJobStatus } = useGetJobApiV1JobsJobIdGetQuery(
    {
      jobId: Number(selectedJobId),
    },
    {
      selectFromResult: ({ data }) => {
        return {
          selectedJobStatus: data?.status,
        }
      },
      skip: selectedJobId === undefined,
    },
  )

  return (
    <Box
      component="div"
      sx={{ display: 'flex', justifyContent: 'flex-end', gap: '6px' }}
    >
      {selectedJobStatus === JobStatus.TO_ACCEPT && enableJobReview && (
        <Edit inDrawer />
      )}
    </Box>
  )
}

const getSelectedJobTitle = (
  t: TFunction<'job', undefined>,
  selectedJobId: string | undefined,
  materialName: string | undefined,
) => {
  return selectedJobId !== undefined && materialName
    ? `#${selectedJobId} ${materialName}`
    : t('common:loading.unavailable')
}

const JobSelectedItemTitle = () => {
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const { t } = useTranslation('jobs')

  const selectedJobId = useSelector((state: RootState) =>
    selectDetailsItemId(state, basePath, selectedTab),
  )

  const { selectedJobMaterialId } = useGetJobApiV1JobsJobIdGetQuery(
    {
      jobId: Number(selectedJobId),
    },
    {
      selectFromResult: ({ data }) => {
        return {
          selectedJobMaterialId: data?.material_id,
        }
      },
      skip: selectedJobId === undefined,
    },
  )

  const { materialName } = useGetMaterialApiV1MaterialsMaterialIdGetQuery(
    {
      materialId: selectedJobMaterialId ?? 0,
    },
    {
      skip: selectedJobMaterialId === undefined,
      selectFromResult: ({ data }) => {
        return {
          materialName: data?.name,
        }
      },
    },
  )

  return <>{getSelectedJobTitle(t, selectedJobId, materialName)}</>
}

const JobDetails = () => {
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const { t } = useTranslation('jobs')

  const selectedJobId = useSelector((state: RootState) =>
    selectDetailsItemId(state, basePath, selectedTab),
  )

  const isTheOnlySelected = useSelector(
    (state: RootState) =>
      selectDetailsNumSelected(state, basePath, selectedTab) === 1,
  )

  const { isLoading, error } = useGetJobApiV1JobsJobIdGetQuery(
    {
      jobId: Number(selectedJobId),
    },
    {
      selectFromResult: ({ data, isLoading, error }) => {
        return {
          isLoading,
          error,
        }
      },
      skip: selectedJobId === undefined,
    },
  )

  return !isTheOnlySelected || error ? (
    <ExtendedDetails
      loading={false}
      close={() => store.dispatch(setDrawerOpen({ value: false }))}
      title={<Title />}
      subtitle={error && localizeError(t, ensureError(error))}
      banner={
        <>
          <ViewportContainer sx={{ height: '100%' }}>
            <img alt="" src="/images/noBuildSelected.svg" />
          </ViewportContainer>
        </>
      }
    ></ExtendedDetails>
  ) : (
    <ExtendedDetails
      loading={isLoading}
      close={() => {
        store.dispatch(setDrawerOpen({ value: false }))
        if (basePath === 'timeline') {
          store.dispatch(
            invalidateDetailsItem({ page: basePath, tab: selectedTab }),
          )
        }
      }}
      title={<JobSelectedItemTitle />}
      banner={<JobViewport />}
      entries={{
        [t('details.jobInformation')]: {
          startOpen: true,
          content: <JobInformation />,
        },
        [t('details.parts')]: {
          startOpen: true,
          content: <JobPartsList selectedJobId={selectedJobId} />,
        },
      }}
      footer={<JobDetailsFooter />}
    ></ExtendedDetails>
  )
}

export { JobDetails }
