import { Box } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectIsThumbnailCaching } from '../../store/slices/thumbnailsCacheSlice'
import { RootState } from '../../store/store'
import { getThumbnailFromIndexedDB } from '../../utils/indexeddb'
import { PartsSvg } from '../Icon/Icon'
import { Thumbnail } from './Thumbnail' // Import Thumbnail component

interface PartThumbnailProps {
  PartId: number
  PartStatus: number
}

export const PartThumbnail: FC<PartThumbnailProps> = ({
  PartId,
  PartStatus,
}) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)

  const isPartThumbnailCaching = useSelector((state: RootState) =>
    selectIsThumbnailCaching(state, PartId.toString()),
  )

  useEffect(() => {
    const getThumbnail = async () => {
      if (isPartThumbnailCaching) {
        setLoading(true)
        return
      }

      const cachedThumbnail = await getThumbnailFromIndexedDB(PartId.toString())

      if (cachedThumbnail === null) {
        setLoading(false)
        return
      }

      const blobURL = URL.createObjectURL(cachedThumbnail)
      setImageUrl(blobURL)
      setLoading(false)
    }

    getThumbnail()
  }, [PartId, PartStatus, isPartThumbnailCaching])

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        minWidth: '40px',
      }}
    >
      <Thumbnail
        imageUrl={imageUrl}
        loading={loading}
        fallback={<PartsSvg width={40} height={40} opacity={0.3} />}
      />
    </Box>
  )
}
