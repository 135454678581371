import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { FC, Suspense, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import { AuthIdentity } from './Identity'
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate'
import { ProgressBottomDrawer } from './components'
import { JiraServiceDeskWidget } from './components/JSD/JSD'
import { Loading } from './components/Loading'
import { Snackbar } from './components/Snackbar/Snackbar'
import { AuthenticationGuard } from './components/authentication-guard'
import {
  getStorageValue,
  LOCAL_STORAGE_KEYS,
  setStorageValue,
} from './hooks/localstorage'
import i18n from './i18n'
import { LoadLinks } from './links'
import { Devices } from './pages/Devices'
import { Inbox } from './pages/Inbox'
import { Manage } from './pages/Manage'
import { OnBoarding } from './pages/OnBoarding'
import { Parts } from './pages/Parts'
import { PrintJobs } from './pages/PrintJobs'
import { Test } from './pages/Test'
import { TimelineContainer } from './pages/Timeline/TimelineContainer'
import { sec } from './store/security'

const LandingPageRedirect = ({
  landingPath,
  newUserLandingPath,
}: {
  landingPath: string
  newUserLandingPath?: string
}) => {
  const isNewUser = getStorageValue(LOCAL_STORAGE_KEYS.isNewUser, false)
  const navigate = useNavigate()

  useEffect(() => {
    if (isNewUser) {
      if (newUserLandingPath) {
        setStorageValue(LOCAL_STORAGE_KEYS.isNewUser, false)
        navigate(newUserLandingPath)
        return
      }
    }
    navigate(landingPath)
  })
  return <>{null}</>
}

function InnerApp() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
  const { PrimaryLinks, FooterLinks } = LoadLinks()

  const inbox = () => (
    <Suspense fallback={<Loading />}>
      <AuthIdentity />
      <Inbox primaryLinks={PrimaryLinks} footerLinks={FooterLinks}></Inbox>
    </Suspense>
  )
  const settings = () => (
    <Suspense fallback={<Loading />}>
      <AuthIdentity />
      <Manage primaryLinks={PrimaryLinks} footerLinks={FooterLinks}></Manage>
    </Suspense>
  )
  const devices = () => (
    <Suspense fallback={<Loading />}>
      <AuthIdentity />
      <Devices primaryLinks={PrimaryLinks} footerLinks={FooterLinks}></Devices>
    </Suspense>
  )
  const test = () => (
    <Suspense fallback={<Loading />}>
      <AuthIdentity />
      <Test primaryLinks={PrimaryLinks} footerLinks={FooterLinks} />
    </Suspense>
  )
  const parts = () => (
    <Suspense fallback={<Loading />}>
      <AuthIdentity />
      <Parts primaryLinks={PrimaryLinks} footerLinks={FooterLinks}></Parts>
    </Suspense>
  )
  const timeline = () => (
    <Suspense fallback={<Loading />}>
      <AuthIdentity />
      <TimelineContainer
        primaryLinks={PrimaryLinks}
        footerLinks={FooterLinks}
      ></TimelineContainer>
    </Suspense>
  )
  const printJobs = () => (
    <Suspense fallback={<Loading />}>
      <AuthIdentity />
      <PrintJobs
        primaryLinks={PrimaryLinks}
        footerLinks={FooterLinks}
      ></PrintJobs>
    </Suspense>
  )
  const landing = () => (
    <Suspense fallback={<Loading />}>
      <AuthIdentity />
      <LandingPageRedirect
        landingPath="/devices"
        newUserLandingPath="/manage"
      />
    </Suspense>
  )
  const subscription = () => {
    window.location.href = '/subscription/'
    return <></>
  }

  const { getAccessTokenSilently } = useAuth0()
  sec.setAccessTokenSilently(getAccessTokenSilently)

  const APIAuthorizer: FC = () => {
    const { getAccessTokenSilently } = useAuth0()
    sec.setAccessTokenSilently(getAccessTokenSilently)

    return null
  }

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s - Asiga"></Helmet>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <I18nextProvider i18n={i18n}>
            <SentryRoutes>
              <Route
                path="/"
                element={<AuthenticationGuard component={landing} />}
              />
              <Route
                path="/inbox/:id?"
                element={<AuthenticationGuard component={inbox} />}
              />
              <Route
                path="/manage/:id?"
                element={<AuthenticationGuard component={settings} />}
              />
              <Route
                path="/parts/:id?"
                element={<AuthenticationGuard component={parts} />}
              />
              <Route
                path="/jobs/:id?"
                element={<AuthenticationGuard component={printJobs} />}
              />
              <Route
                path="/timeline/:id?"
                element={<AuthenticationGuard component={timeline} />}
              />
              <Route
                path="/devices/:id?"
                element={<AuthenticationGuard component={devices} />}
              />
              <Route
                path="/subscription"
                element={<AuthenticationGuard component={subscription} />}
              />
              <Route path="/onboarding" element={<OnBoarding />} />
              {import.meta.env.VITE_SHOW_TEST_PAGE === 'true' && (
                <Route
                  path="/test"
                  element={<AuthenticationGuard component={test} />}
                />
              )}
            </SentryRoutes>
            <ProgressBottomDrawer />
            <APIAuthorizer />
          </I18nextProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
      <JiraServiceDeskWidget apiKey={import.meta.env.VITE_JSD_API_KEY} />
      <Snackbar />
    </HelmetProvider>
  )
}

export const App = Sentry.withProfiler(InnerApp)
