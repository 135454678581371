import { ProgressStatus } from '../store/baseClientApi'

export const partProgressStatusToTranslatedKey = (
  status: ProgressStatus,
): string => {
  switch (status) {
    case 0:
      return 'status.partIdentification.partIdentification'
    case 1:
      return 'status.partIdentification.identifyingPartType'
    case 2:
      return 'status.partIdentification.confirmPartType'
    case 3:
      return 'status.acceptPartForPrinting.acceptPartForPrinting'
    case 4:
      return 'status.acceptPartForPrinting.partAccepted'
    case 5:
      return 'status.rejected'
    case 6:
      return 'status.orientatePart.orientatingPart'
    case 7:
      return 'status.orientatePart.orientationFailed'
    case 8:
      return 'status.nestPrintJob.partQueued'
    case 9:
      return 'status.nestPrintJob.nestingPrintJob'
    case 10:
      return 'status.nestPrintJob.nestingFailed'
    case 11:
      return 'status.approvePrintJob.printJobPendingApproval'
    case 12:
      return 'status.sendToPrinter.slicingPrintJob'
    case 13:
      return 'status.sendToPrinter.slicingFailed'
    case 14:
      return 'status.sendToPrinter.sendingToPrinter'
    case 15:
      return 'status.sendToPrinter.sendingFailed'
    case 16:
      return 'status.print.printing'
    case 17:
      return 'status.print.printed'
    case 18:
      return 'status.print.printFailed'
    case 19:
      return 'status.sendToPrinter.printJobDownloaded'
    default:
      return 'common:loading.unavailable'
  }
}
