import {
  Box,
  LinearProgress,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { SearchField } from '../../components'
import { ActionBar } from '../../components/ActionBar/ActionBar'
import { Dashboard, DashboardLink } from '../../components/Dashboard/Dashboard'
import {
  CancelledSvg,
  CompleteSvg,
  ToReviewSvg,
} from '../../components/Icon/Icon'
import { Edit } from '../../components/JobEdit/JobEdit'
import {
  JOB_TABLE_STATE_NAME,
  JobsList,
} from '../../components/JobsList/JobsList'
import { ReduxPerPage } from '../../components/PerPageSelector/PerPageSelector'
import { TabsTablePageHeader } from '../../components/Tabs/Tabs'
import { UnderConstruction } from '../../components/UnderConstruction/UnderConstruction'
import { Tabs } from '../../constants/tabs'
import { JobStatus, useGetJobsApiV1JobsGetQuery } from '../../store/clientApi'
import {
  invalidateDetailsItem,
  selectDetailsNumSelected,
  selectDrawerOpen,
  setDrawerOpen,
} from '../../store/slices/detailsDrawerSlice'
import { getTab } from '../../store/slices/tabSlice'
import {
  invalidate,
  selectIsChecked,
  selectNumChecked,
  selectQueryArgs,
  selectQueryPerPage,
  selectQuerySearchValue,
  setPerPage,
  setRtkArgs,
  setSearchValue,
} from '../../store/slices/tableSlice'
import {
  clearStatusEvent,
  selectStatusBadge,
} from '../../store/slices/wsEventSlice'
import { RootState, store } from '../../store/store'

const tabPath = 'jobs'
const defaultTab = Tabs.PRINT_JOBS.TO_REVIEW

interface PrintJobProps {
  primaryLinks: DashboardLink[]
  footerLinks: DashboardLink[]
}
const invalidateTable = () => store.dispatch(invalidate(JOB_TABLE_STATE_NAME))

const JobActionBar = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('jobs')
  const theme = useTheme()
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const breakpointLg = useMediaQuery(theme.breakpoints.up('lg'))
  const [numSelected, setNumSelected] = useState(0)
  const newNumSelected =
    useSelector((state: RootState) =>
      selectDetailsNumSelected(state, basePath, selectedTab),
    ) ?? 0

  const isSelected = useSelector((state: RootState) =>
    selectIsChecked(state, JOB_TABLE_STATE_NAME),
  )
  const searchValue = useSelector((state: RootState) =>
    selectQuerySearchValue(state, JOB_TABLE_STATE_NAME),
  )

  useEffect(() => {
    if (newNumSelected > numSelected && newNumSelected === 1 && breakpointLg) {
      store.dispatch(setDrawerOpen({ value: true }))
    }
    if (numSelected !== newNumSelected) {
      setNumSelected(newNumSelected)
    }
  }, [newNumSelected, numSelected, breakpointLg])

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'center',
        width: '100%',
        gap: '6px',
      }}
    >
      {isSelected ? (
        <ActionBar
          selector={(state: RootState) =>
            selectNumChecked(state, JOB_TABLE_STATE_NAME)
          }
          handleCloseActionBar={() => {
            invalidateTable()
            store.dispatch(
              invalidateDetailsItem({ page: basePath, tab: selectedTab }),
            )
          }}
        >
          <Edit />
        </ActionBar>
      ) : (
        <>
          <SearchField
            placeholder={t('label.search')}
            value={searchValue}
            onChange={(searchInput) => {
              store.dispatch(
                setSearchValue({
                  name: JOB_TABLE_STATE_NAME,
                  value: searchInput,
                }),
              )
            }}
          />
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {children}
          </Box>
        </>
      )}
    </Box>
  )
}

const TO_REVIEW_FILTER = [
  JobStatus.TO_ACCEPT,
  JobStatus.CREATED,
  JobStatus.PENDING,
  JobStatus.PROCESSING,
]
const ACCEPTED_FILTER = [
  JobStatus.PRINTED,
  JobStatus.PRINT_PENDING,
  JobStatus.PRINTING,
  JobStatus.FAILED,
  JobStatus.PREPARING_TO_SEND,
]
const REJEPTED_FILTER = [
  JobStatus.REJECTED,
  JobStatus.OFFLINE,
  JobStatus.CANCELLED,
]

const PrintJobData = () => {
  const tab =
    useSelector((state: RootState) => getTab(state, tabPath)) ?? defaultTab
  const queryArgs = useSelector((state: RootState) =>
    selectQueryArgs(state, JOB_TABLE_STATE_NAME),
  )

  const rtkArgs = useMemo(() => {
    const statusFilter =
      tab === Tabs.PRINT_JOBS.TO_REVIEW
        ? TO_REVIEW_FILTER
        : tab === Tabs.PRINT_JOBS.ACCEPTED
          ? ACCEPTED_FILTER
          : tab === Tabs.PRINT_JOBS.REJECTED
            ? REJEPTED_FILTER
            : []

    const statusQuery =
      statusFilter.length > 0
        ? `(${statusFilter.map((status) => `status:${status}`).join(' OR ')})`
        : undefined

    const defaultSortField = tab === Tabs.PRINT_JOBS.TO_REVIEW ? 'status' : 'id'
    const defaultSortOrder = 'desc'
    const sortField =
      queryArgs.sortField === '' ? defaultSortField : queryArgs.sortField
    const sortOrder =
      queryArgs.sortField === '' ? defaultSortOrder : queryArgs.sortOrder

    return {
      page: queryArgs.page !== 0 ? queryArgs.page - 1 : 0,
      perPage: queryArgs.perPage,
      sorting: `${sortField}:${sortOrder === 'asc' ? '1' : '-1'}`,
      query:
        queryArgs.searchValue !== ''
          ? `(material.name:"*${queryArgs.searchValue}*")` +
            (statusQuery ? `AND ${statusQuery}` : '')
          : statusQuery,
    }
  }, [queryArgs, tab])

  useEffect(() => {
    store.dispatch(setRtkArgs({ name: JOB_TABLE_STATE_NAME, value: rtkArgs }))
  }, [rtkArgs])

  useGetJobsApiV1JobsGetQuery(rtkArgs, {})

  return <>{null}</>
}

const Header = () => {
  const { t } = useTranslation('jobs')

  return (
    <TabsTablePageHeader
      tabPath={tabPath}
      table={JOB_TABLE_STATE_NAME}
      onEnterTab={() => invalidateTable()}
      title={t('title.printJobs')}
      defaultTabValue={defaultTab}
      tabs={[
        {
          label: t('label.toReview'),
          icon: <ToReviewSvg color="inherit" />,
          value: Tabs.PRINT_JOBS.TO_REVIEW,
          badgeContent: (state: RootState) =>
            selectStatusBadge(state, 'jobs', JobStatus.TO_ACCEPT),
          whileSelected: () => {
            store.dispatch(
              clearStatusEvent({
                type: 'jobs',
                status: JobStatus.TO_ACCEPT,
              }),
            )
          },
        },
        {
          label: t('label.accepted'),
          icon: <CompleteSvg color="inherit" />,
          value: Tabs.PRINT_JOBS.ACCEPTED,
        },
        {
          label: t('label.rejected'),
          icon: <CancelledSvg color="inherit" />,
          value: Tabs.PRINT_JOBS.REJECTED,
        },
      ]}
    />
  )
}

const PrintJobs: FC<PrintJobProps> = (props) => {
  const { releasePrintJobsUi } = useFlags()

  const [isReleasePrintJobsUiTimedOut, setIsReleasePrintJobsUiTimedOut] =
    useState<boolean>(false)

  // Feature Flag timeout as both error and loading are treated as undefined
  useEffect(() => {
    let timeoutId: any = null
    // Start timeout if allowDevicesUi is undefined
    if (!releasePrintJobsUi) {
      timeoutId = setTimeout(() => {
        setIsReleasePrintJobsUiTimedOut(true)
      }, 3000) // 3 seconds
    }

    // Clear timeout on component unmount or if allowDevicesUi becomes defined
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [releasePrintJobsUi])

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const detailsOpen = useSelector((state: RootState) => selectDrawerOpen(state))

  return (
    <>
      <PrintJobData />
      {releasePrintJobsUi ? (
        <Dashboard
          primaryLinks={props.primaryLinks}
          footerLinks={props.footerLinks}
        >
          <Box
            component="div"
            sx={{ height: '100%', position: 'relative', width: '100%' }}
          >
            <Box
              component="div"
              sx={{
                display: 'flex',
                alignContent: 'stretch',
                justifyContent: 'space-evenly',
                gap: '1em',
                width: '100%',
                height: '100%',
              }}
            >
              <Box
                component="div"
                sx={{
                  display: mdDown ? (detailsOpen ? 'none' : 'flex') : 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  width: '100%',
                  bgcolor: (theme: Theme) => theme.surface.low,
                  padding: '1em',
                  borderRadius: '1em',
                  overflow: 'auto',
                }}
              >
                <Header />
                <JobActionBar>
                  <ReduxPerPage
                    dispatch={(state) =>
                      store.dispatch(
                        setPerPage({
                          name: JOB_TABLE_STATE_NAME,
                          value: state,
                        }),
                      )
                    }
                    selector={(state) =>
                      selectQueryPerPage(state, JOB_TABLE_STATE_NAME)
                    }
                  />
                </JobActionBar>
                <JobsList />
              </Box>
            </Box>
          </Box>
        </Dashboard>
      ) : (
        <Dashboard
          primaryLinks={props.primaryLinks}
          footerLinks={props.footerLinks}
        >
          {releasePrintJobsUi === undefined && !isReleasePrintJobsUiTimedOut ? (
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                bgcolor: (theme: Theme) => theme.surface.low,
                padding: '1em',
                borderRadius: '1em',
                overflow: 'auto',
              }}
            >
              <Header />
              <LinearProgress />
            </Box>
          ) : (
            <UnderConstruction />
          )}
        </Dashboard>
      )}
    </>
  )
}

export const JOB_DETAILS_STATE = 'jobDetailsState'
export { PrintJobs }
