import { MenuItem } from '@mui/material'
import React, { FC, ReactNode, useState } from 'react'
import { Button } from '../Button/Button'
import { ExpandedSvg } from '../Icon/Icon'
import { Menu } from '../Menu/Menu'

interface DropdownButtonProps {
  options: Array<string>
  translatedOptions?: Array<string>
  disabled?: boolean
  label: string
  id?: string
  startIcon?: ReactNode
  variant?: 'contained' | 'outlined' | 'text'
  onChange: (arg0: string) => void
}
/**
 * Button component that has a dropdown of selectable values (strings).
 * Note: Button label is fixed, i.e., selecting an option does not change the button label.
 * @param options: Array of strings that are the selectable options
 * @param disabled: Boolean If the button is clickable or not
 * @param label: The text that will appear on the button itself
 * @param id: unique string identifier for the dom element
 * @param onChange: function that takes a string parameter representing the selected string from
 * the dropdown
 */
export const DropdownButton: FC<DropdownButtonProps> = ({
  options,
  translatedOptions,
  startIcon,
  disabled = false,
  label,
  id = '',
  variant,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    if (disabled) return

    if (anchorEl !== null) {
      setAnchorEl(null)
    } else {
      setAnchorEl(e.currentTarget)
    }
  }

  const handleSelectOption = (
    e: React.MouseEvent<HTMLElement>,
    option: string,
  ) => {
    e.preventDefault()
    setAnchorEl(null)
    onChange(option)
  }

  return (
    <div>
      <div onClick={handleButtonClick}>
        <Button
          variant={variant}
          startIcon={startIcon}
          endIcon={<ExpandedSvg color="inherit" />}
          id={id}
          disabled={disabled}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: variant === 'text' ? '0.5em 1em' : undefined,
          }}
        >
          {label}
        </Button>
      </div>
      {open && (
        <Menu
          innerProps={{
            anchorEl: anchorEl,
            open: open,
            onClose: () => setAnchorEl(null),
            onClick: () => setAnchorEl(null),
          }}
          borderRadius="0.5rem"
          noPadding
          hideArrow
        >
          {options.map((option, index) => {
            return (
              <MenuItem
                onClick={(e: React.MouseEvent<HTMLElement>) =>
                  handleSelectOption(e, option)
                }
                key={`${index}_${option}`}
              >
                {translatedOptions ? translatedOptions[index] : option}
              </MenuItem>
            )
          })}
        </Menu>
      )}
    </div>
  )
}
