import { Box, CircularProgress, Theme, Typography } from '@mui/material'
import { FC, Fragment, ReactNode } from 'react'
import { CancelledSvg, CompleteSvg, MissingSvg, TodoSvg } from '../Icon/Icon'

interface ChecklistItem {
  status?: ChecklistItemStatus
  text: string | ReactNode
}

type ChecklistItemStatus =
  | 'NOT STARTED'
  | 'PENDING APPROVAL'
  | 'IN PROGRESS'
  | 'SUCCESS'
  | 'FAILED'

interface ChecklistProps {
  items: ChecklistItem[]
}

interface ChecklistStatusIconProps {
  status: ChecklistItemStatus
}

const getIconColour = (status: ChecklistItemStatus) => {
  switch (status) {
    case 'NOT STARTED':
      return (theme: Theme) => theme.palette.text.secondary + '!important'
    case 'PENDING APPROVAL':
      return (theme: Theme) => theme.palette.warning.main + '!important'
    case 'IN PROGRESS':
      return (theme: Theme) => theme.palette.warning.main + '!important'
    case 'SUCCESS':
      return (theme: Theme) => theme.palette.success.main + '!important'
    case 'FAILED':
      return (theme: Theme) => theme.palette.error.main + '!important'
    default:
      return (theme: Theme) => theme.palette.text.secondary + '!important'
  }
}

const ChecklistStatusIcon: FC<ChecklistStatusIconProps> = ({ status }) => {
  const ChecklistStatusIconSVG: FC<ChecklistStatusIconProps> = ({ status }) => {
    switch (status) {
      case 'NOT STARTED':
        return <MissingSvg width="29px" height="24px" />
      case 'PENDING APPROVAL':
        return <TodoSvg width="24px" height="24px" />
      case 'IN PROGRESS':
        return (
          <CircularProgress
            color="warning"
            size={'21px'}
            sx={{ width: '24px', height: '24px' }}
          />
        )
      case 'SUCCESS':
        return <CompleteSvg width="24px" height="24px" />
      case 'FAILED':
        return <CancelledSvg width="24px" height="24px" />
      default:
        return <></>
    }
  }

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '24px',
        height: '24px',
        color: getIconColour(status),
      }}
    >
      <ChecklistStatusIconSVG status={status} />
    </Box>
  )
}

export const Checklist: FC<ChecklistProps> = ({ items }) => {
  const getIndexBeforeNotStarted = () => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].status === 'NOT STARTED') {
        // Return the previous index or the first item if it's the first one
        return i === 0 ? 0 : i - 1
      }
    }
    // Return the last item if 'NOT STARTED' is not found
    return items.length - 1
  }

  const indexBeforeNotStarted = getIndexBeforeNotStarted()

  return (
    <Box
      component="div"
      sx={{ display: 'flex', flexDirection: 'column', gap: '1px' }}
    >
      {items.map((item, index) => {
        return (
          <Fragment key={`${item.text}-${index}`}>
            <Box
              component="div"
              sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}
            >
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '24px',
                  height: '24px',
                }}
              >
                <ChecklistStatusIcon status={item.status ?? 'NOT STARTED'} />
              </Box>
              <Typography
                fontSize={'0.9em'}
                variant={index === indexBeforeNotStarted ? 'h1' : 'subtitle1'}
              >
                {item.text}
              </Typography>
            </Box>
            {index < items.length - 1 && (
              <Box
                component="div"
                sx={{
                  width: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  component="div"
                  sx={{
                    height: '1em',
                    width: '2px',
                    backgroundColor:
                      items?.[index]?.status === 'SUCCESS'
                        ? (theme: Theme) =>
                            theme.palette.success.main + '!important'
                        : (theme: Theme) =>
                            theme.palette.text.secondary + '!important',
                  }}
                />
              </Box>
            )}
          </Fragment>
        )
      })}
    </Box>
  )
}

export type { ChecklistItem, ChecklistItemStatus }
