export const Tabs = {
  INBOX: {
    CONFIRM: 'confirm',
    REVIEW: 'review',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
  },
  PARTS: {
    ALL_PARTS: 'all',
    PARTS_QUEUED: 'queued',
  },
  PRINT_JOBS: {
    TO_REVIEW: 'to-review',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
  },
  DEVICES: {
    ALL: 'all',
    ATTENTION: 'attention',
  },
  TIMELINE: {
    ALL: 'all',
  },
  MANAGE: {
    USERS: 'users',
    TEMPLATES: 'templates',
    APPS: 'apps',
    CONNECTED_APPS: 'connected-apps',
  },
}
