import { MenuItem } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Dispatch, SetStateAction, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { deviceStateToColor, ensureError, localizeError } from '../../helpers'
import {
  clientApi,
  useControlDeviceSchedulingMutation,
} from '../../store/clientApi'
import {
  invalidateDetailsItem,
  setDetailsItem,
  setDrawerOpen,
} from '../../store/slices/detailsDrawerSlice'
import {
  selectRtkData,
  selectTableChecked,
  setChecked,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { deviceMessage } from '../../utils/getDeviceMessage'
import { ProgressCard } from '../Cards/Cards'
import { DEVICES_TABLE_STATE_NAME } from '../DevicesList/DevicesList'
import { DialogBox } from '../DialogBox'
import { ExcludedSvg, NotConnectedSvg } from '../Icon/Icon'

export const DeviceSchedulingMenuItem = ({
  thingName,
  availableInFunnel,
  name,
}: {
  thingName?: string
  availableInFunnel: boolean
  name: string
}) => {
  const { t } = useTranslation('devices')
  const [changeScheduling] = useControlDeviceSchedulingMutation({})
  const [openWarning, setOpenWarning] = useState(false)

  const change = async (thingName: string) => {
    try {
      await changeScheduling({
        serialNumber: thingName,
        controlDeviceSchedulingRequest: {
          enabled: !availableInFunnel,
        },
      }).unwrap()
      toast.success(
        t(
          availableInFunnel ? 'message.excludeDevice' : 'message.includeDevice',
          { device: name },
        ),
      )
    } catch (err) {
      toast.error(localizeError(t, ensureError(err)))
    }
  }

  return thingName !== undefined ? (
    <>
      <MenuItem
        onClick={(e) => {
          if (availableInFunnel) setOpenWarning(true)
          else change(thingName)
        }}
      >
        {availableInFunnel
          ? t('button.excludeDevice')
          : t('button.includeDevice')}
      </MenuItem>
      <DialogBox
        title={t('title.excludeDevice', { device: name })}
        message={t('message.willExcludeDevice')}
        args={undefined}
        onClose={(approve) => {
          if (approve) change(thingName)
        }}
        open={openWarning}
        setOpen={setOpenWarning}
        confirmText={t('button.exclude')}
        declineText={t('common:button.cancel')}
      ></DialogBox>
    </>
  ) : (
    <></>
  )
}

export const DeviceCard = ({
  thingName,
  setConfirmRemove,
}: {
  thingName: string
  setConfirmRemove: Dispatch<SetStateAction<string | undefined>>
}) => {
  const { enableRemoveDeviceButton } = useFlags()
  const { t } = useTranslation('devices')
  const UNAVAILABLE = t('common:loading.unavailable')
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]

  const checked = useSelector((state: RootState) =>
    selectTableChecked(state, DEVICES_TABLE_STATE_NAME),
  )
  const selectedDevice = Object.keys(checked).find(
    (thingName) => checked[thingName],
  )
    ? {
        thingName: Object.keys(checked).find((thingName) => checked[thingName]),
      }
    : undefined

  const handleDeviceSelect = (thingName: string, value: boolean) => {
    const currentSelection = selectedDevice?.thingName
    if (currentSelection !== undefined) {
      // deselect current
      store.dispatch(
        setChecked({
          name: DEVICES_TABLE_STATE_NAME,
          checked: currentSelection,
          value: false,
        }),
      )
      store.dispatch(
        invalidateDetailsItem({ page: basePath, tab: selectedTab }),
      )
    }
    // set selected thing
    store.dispatch(
      setChecked({
        name: DEVICES_TABLE_STATE_NAME,
        checked: thingName,
        value,
      }),
    )
    store.dispatch(
      setDetailsItem({
        page: basePath,
        tab: selectedTab,
        itemType: 'Device',
        selectedId: thingName,
      }),
    )
  }

  const currDevice = useSelector((state: RootState) =>
    selectRtkData(
      state,
      DEVICES_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )?.data?.content?.find((device) => device.thing_name === thingName),
  )

  const name = currDevice?.status?.name ?? UNAVAILABLE
  const model = currDevice?.status?.model ?? UNAVAILABLE

  const elapsed = currDevice?.status?.elapsed ?? 0
  const remaining = currDevice?.status?.remaining ?? 0
  const printTime = elapsed + remaining
  const progress =
    printTime === 0 || currDevice?.status?.connected === false
      ? 0
      : (elapsed / printTime) * 100

  return (
    <ProgressCard
      title={name}
      subtitle={model}
      value={progress}
      color={deviceStateToColor(currDevice)}
      icon={
        !currDevice?.status?.connected ? (
          <NotConnectedSvg color="inherit" width="16px" height="16px" />
        ) : undefined
      }
      info={
        currDevice?.attributes.available_in_funnel === false
          ? t('label.excludedDevice')
          : undefined
      }
      infoIcon={
        currDevice?.attributes.available_in_funnel === false
          ? ExcludedSvg
          : undefined
      }
      message={deviceMessage(currDevice)}
      selected={
        selectedDevice && selectedDevice.thingName === currDevice?.thing_name
      }
      onClick={() => {
        if (currDevice?.thing_name) {
          store.dispatch(setDrawerOpen({ value: true }))
          handleDeviceSelect(currDevice.thing_name, true)
        }
      }}
    >
      <MenuItem
        disabled={!enableRemoveDeviceButton}
        onClick={() => setConfirmRemove(currDevice?.thing_name)}
      >
        {t('common:button.remove')}
      </MenuItem>
      <DeviceSchedulingMenuItem
        thingName={currDevice?.thing_name}
        name={currDevice?.status?.name ?? UNAVAILABLE}
        availableInFunnel={currDevice?.attributes.available_in_funnel ?? false}
      />
    </ProgressCard>
  )
}
