import { Box, Divider, Typography } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { DropdownButton } from '../../../components'
import { MachinesSvg } from '../../../components/Icon/Icon'
import { Tab, Tabs } from '../../../components/Tabs/Tabs'
import { Tabs as TabsConstants } from '../../../constants/tabs'

interface TimelineHeaderProps {
  timelineHours: number
  setTimelineHours: Dispatch<SetStateAction<number>>
}

export const TimelineHeader: FC<TimelineHeaderProps> = ({
  timelineHours,
  setTimelineHours,
}) => {
  const { t } = useTranslation('timeline')
  return (
    <>
      <Typography variant="h1">{t('title.timeline')}</Typography>
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          minHeight: 'max-content',
          width: '100%',
        }}
      >
        <Tabs
          tab={TabsConstants.TIMELINE.ALL}
          setTab={() => {
            return
          }}
          defaultTab="all"
          aria-label={t('label.devicesTab')}
        >
          <Tab
            selectedTab={TabsConstants.TIMELINE.ALL}
            icon={<MachinesSvg color="inherit" />}
            label={t('label.allMachines')}
            value="all"
          />
        </Tabs>
        <Box
          component="div"
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
            boxSizing: 'border-box',
            maxHeight: '72px',
          }}
        >
          <DropdownButton
            variant="text"
            onChange={(hours: string) => {
              setTimelineHours(parseInt(hours))
            }}
            label={t('viewHours', { count: timelineHours })}
            options={['8', '12', '24']}
          />
        </Box>
      </Box>
      <Divider flexItem />
    </>
  )
}
