import { DeviceStatusRead } from '../store/clientApi'

export const IsDeviceInAnErrorState = (device: DeviceStatusRead) => {
  return (
    (device.status?.paused_by_user === false &&
      device.status?.state === 'Pausing') ||
    (device.status?.paused_by_user === false &&
      device.status?.state === 'Paused') ||
    device.status?.connected === false
  )
}
