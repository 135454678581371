import { Box, LinearProgress, TableCell, TableRow } from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { partClassification } from '../../constants/partClassification'
import {
  clientApi,
  PaginatedTemplateReadPublic,
  PartReadPublic,
  TemplateReadPublic,
  useGetPartsApiV1PartsGetQuery,
} from '../../store/clientApi'
import { useAppDispatch } from '../../store/hooks'
import {
  addSelectedId,
  removeSelectedId,
  setDetailsItem,
  setSelectedIds,
} from '../../store/slices/detailsDrawerSlice'
import {
  createPartQuery,
  invalidateChecked,
  invalidateDetailsItem,
  removeDeleted,
  selectCheckedState,
  selectIsChecked,
  selectNumChecked,
  selectPartsPerPage,
  selectSelectedParts,
  selectTemplateChecked,
  setCheckedTable,
  setPartsPerPage,
  toggleChecked,
} from '../../store/slices/partQueueSlice'
import {
  selectQuerySearchValue,
  selectQuerySortField,
  selectQuerySortOrder,
  selectRtkData,
  setCanCollapsible,
  setSortField,
  setSortOrder,
} from '../../store/slices/tableSlice'
import { cachePartThumbnails } from '../../store/slices/thumbnailsCacheSlice'
import { RootState, store } from '../../store/store'
import { Button } from '../Button/Button'
import { Checkbox } from '../Checkbox/Checkbox'
import { GenericPartActionBar } from '../PartActionBar/PartActionBar'
import { PartProgressStatus } from '../PartStatus/PartStatus'
import { PartSearch } from '../PartsBody/PartsBody'
import { Column, DynamicTable, Sort } from '../Table/Table'
import { PartThumbnail } from '../Thumbnail/PartThumbnail'

const PartsUpdates = ({ template }: { template: string }) => {
  const checked = useSelector((state: RootState) =>
    selectTemplateChecked(state, template),
  )

  const tableData = useSelector((state: RootState) => {
    const searchValue = selectQuerySearchValue(state, PARTS_QUEUE_TABLE_NAME)
    const sortOrder = selectQuerySortOrder(state, PARTS_QUEUE_TABLE_NAME)
    const sortField = selectQuerySortField(state, PARTS_QUEUE_TABLE_NAME)
    const perPage = selectPartsPerPage(state, template)

    return clientApi.endpoints.getPartsApiV1PartsGet.select(
      createPartQuery(template, searchValue, sortField, sortOrder, perPage),
    )(state).data?.content
  })

  useEffect(() => {
    const ids = new Set((tableData ?? []).map((part) => part.id.toString()))
    const deletedChecked = Object.keys(checked ?? {}).filter(
      (id) => !ids.has(id),
    )
    const deleted = [...new Set([...deletedChecked])]
    if (deleted.length > 0)
      store.dispatch(removeDeleted({ template: template, ids: deleted }))
  }, [tableData, checked, template])
  return <>{null}</>
}

const PartRow = ({
  template,
  part,
  checkRange,
}: {
  template: string
  part: PartReadPublic
  checkRange: (part: PartReadPublic) => void
}) => {
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const { t, i18n } = useTranslation('inbox')
  const selected = useSelector((state: RootState) =>
    selectCheckedState(state, template, part.id),
  )

  const handleClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    event.stopPropagation()

    if (event.ctrlKey) {
      if (selected) {
        store.dispatch(
          removeSelectedId({
            page: basePath,
            tab: selectedTab,
            selectedId: part.id.toString(),
          }),
        )
      } else {
        store.dispatch(
          addSelectedId({
            page: basePath,
            tab: selectedTab,
            selectedId: part.id.toString(),
            itemType: 'Part',
          }),
        )
      }

      store.dispatch(
        toggleChecked({
          template: template,
          id: part.id.toString(),
        }),
      )
    } else if (event.shiftKey) {
      checkRange(part)
    } else {
      store.dispatch(
        setCheckedTable({
          template: template,
          checked: { [part.id.toString()]: true },
          lastChecked: part.id.toString(),
        }),
      )

      store.dispatch(
        setDetailsItem({
          page: basePath,
          tab: selectedTab,
          itemType: 'Part',
          selectedId: part.id.toString(),
        }),
      )
    }
  }

  const formattedDate = new Date(part.created_at).toLocaleString(
    [...i18n.languages],
    {
      day: '2-digit',
      month: 'long',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    },
  )
  return (
    <TableRow
      key={`${part.id}`}
      hover
      selected={selected}
      onClick={handleClick}
    >
      <TableCell padding="checkbox" colSpan={1}>
        <PartCheckbox part={part.id.toString()} template={template} />
      </TableCell>
      <TableCell colSpan={1}>
        <Box
          component="div"
          sx={{
            display: 'flex',
            gap: '0.5em',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <PartThumbnail PartId={part.id} PartStatus={part.status} />
          {part.name.toString()}
        </Box>
      </TableCell>
      <TableCell colSpan={1}>
        {t(
          /*
    t('other')
    t('hollowModel')
    t('solidModel')
    t('crownOrToothOrVeneer')
    t('screwInTooth')
    t('die')
    t('flatGingiva')
    t('pillowGingiva')
    t('teethRow')
    t('dentureBase')
    t('denturePartial')
    t('tryInDenture')
    t('occlusalSplint')
    t('surgicalGuide')
    t('bridge')
    t('partialFramework')
    */
          `table.partClassification.${partClassification[part.classification]}`,
        )}
      </TableCell>
      <TableCell>{formattedDate}</TableCell>
      <TableCell>
        <PartProgressStatus status={part.progress_status} />
      </TableCell>
    </TableRow>
  )
}
const CollapsibleDetails = ({
  template,
  index,
  visible,
}: {
  template: string
  index: number
  visible: boolean
}) => {
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const dispatch = useAppDispatch()
  const { t } = useTranslation('inbox')
  const UNAVAILABLE = t('common:loading.unavailable')
  const perPage = useSelector((state: RootState) =>
    selectPartsPerPage(state, template),
  )
  const [awaitingMore, setAwaitingMore] = useState(false)
  const perPageStepSize = 10
  const loadMoreParts = () => {
    setAwaitingMore(true)
    store.dispatch(
      setPartsPerPage({
        templateId: template,
        perPage: perPage + perPageStepSize,
      }),
    )
  }
  const searchValue = useSelector((state: RootState) =>
    selectQuerySearchValue(state, PARTS_QUEUE_TABLE_NAME),
  )
  const sortOrder = useSelector((state: RootState) =>
    selectQuerySortOrder(state, PARTS_QUEUE_TABLE_NAME),
  )
  const sortField = useSelector((state: RootState) =>
    selectQuerySortField(state, PARTS_QUEUE_TABLE_NAME),
  )

  const checkRange = (part: PartReadPublic) => {
    const checked = part.id.toString()
    const state = store.getState()
    const lastChecked = state.partQueue.lastChecked[template]

    if (lastChecked === undefined || lastChecked === checked) {
      store.dispatch(
        setCheckedTable({
          template: template,
          checked: { [part.id.toString()]: true },
          lastChecked: part.id.toString(),
        }),
      )
      store.dispatch(
        setDetailsItem({
          page: basePath,
          tab: selectedTab,
          itemType: 'Part',
          selectedId: checked,
        }),
      )
      return
    }

    const toCheck: { [id: string]: boolean | undefined } = {}
    let insideRange = false
    for (const item of partsData?.content ?? []) {
      const id = item.id.toString()
      if (id === lastChecked || id === checked) {
        if (insideRange) break
        insideRange = true
      }

      if (insideRange) toCheck[id] = true
    }

    toCheck[lastChecked] = true
    toCheck[checked] = true

    const selectedIds = Object.keys(toCheck).filter((id) => toCheck[id])
    if (selectedIds.length === 1) {
      store.dispatch(
        setDetailsItem({
          page: basePath,
          tab: selectedTab,
          itemType: 'Part',
          selectedId: selectedIds[0],
        }),
      )
    } else {
      store.dispatch(
        setSelectedIds({
          page: basePath,
          tab: selectedTab,
          selectedIds: selectedIds,
        }),
      )
    }

    store.dispatch(
      setCheckedTable({
        template: template,
        checked: toCheck,
      }),
    )
  }

  const {
    data: partsData,
    isLoading,
    isFetching,
    error,
  } = useGetPartsApiV1PartsGetQuery(
    createPartQuery(template, searchValue, sortField, sortOrder, perPage),
  )

  useEffect(() => {
    dispatch(cachePartThumbnails(partsData))
  }, [partsData, dispatch])

  useEffect(() => {
    if (!isLoading) {
      store.dispatch(
        setCanCollapsible({
          name: PARTS_QUEUE_TABLE_NAME,
          index: template,
          value: true,
        }),
      )
    }
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (awaitingMore && !isFetching) setAwaitingMore(false)
  }, [awaitingMore, isFetching])
  return (
    <>
      <PartsUpdates template={template} />
      {!visible ? (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={5}></TableCell>
        </TableRow>
      ) : error ? (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={5}>
            <Box
              component="div"
              sx={{
                padding: '0.5em',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {UNAVAILABLE}
            </Box>
          </TableCell>
        </TableRow>
      ) : isLoading ? (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={5}>
            <LinearProgress />
          </TableCell>
        </TableRow>
      ) : partsData?.content !== undefined && partsData?.content?.length > 0 ? (
        <>
          {partsData.content.map((part) => (
            <PartRow
              template={template}
              key={part.id.toString()}
              part={part}
              checkRange={checkRange}
            />
          ))}
          {(!awaitingMore || (awaitingMore && !isFetching)) &&
          (partsData.count ?? 0) < (partsData.total_count ?? 0) ? (
            <TableRow>
              <TableCell colSpan={1}></TableCell>
              <TableCell colSpan={1}>
                <Button variant="text" onClick={loadMoreParts}>
                  {t('button.viewMoreParts')}
                </Button>
              </TableCell>
              <TableCell colSpan={3}></TableCell>
            </TableRow>
          ) : null}
          {awaitingMore && isFetching && (
            <TableRow>
              <TableCell style={{ padding: 0 }} colSpan={5}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          )}
        </>
      ) : (
        <TableRow className={'EmptyRow'}>
          <TableCell colSpan={5}>
            <Box
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {t('message.noParts')}
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const PartCheckbox = ({
  part,
  template,
}: {
  template: string
  part: string
}) => {
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const checkedState = useSelector((state: RootState) =>
    selectCheckedState(state, template, part),
  )

  return (
    <Checkbox
      checked={checkedState}
      onClick={(e) => {
        e.stopPropagation()

        store.dispatch(
          toggleChecked({
            template: template,
            id: part,
          }),
        )

        if (checkedState) {
          store.dispatch(
            removeSelectedId({
              page: basePath,
              tab: selectedTab,
              selectedId: part,
            }),
          )
        } else {
          store.dispatch(
            addSelectedId({
              page: basePath,
              tab: selectedTab,
              selectedId: part,
              itemType: 'Part',
            }),
          )
        }
      }}
    />
  )
}

const EntityCell = ({
  entity,
  index,
  table,
}: {
  entity: string
  index: number
  table: string
}) => {
  const data = useSelector((state: RootState) =>
    selectRtkData(
      state,
      table,
      clientApi.endpoints.getTemplatesApiV1TemplatesGet.select,
    )?.data?.content?.find((template) => template.id.toString() === entity),
  )

  return index === 0 ? (
    <>
      {data?.name} ({data?.parts_queued})
    </>
  ) : (
    <></>
  )
}

export const selectPartsQueueSelectedParts = (state: RootState) =>
  selectSelectedParts(
    state,
    selectQuerySearchValue(state, PARTS_QUEUE_TABLE_NAME),
    selectQuerySortField(state, PARTS_QUEUE_TABLE_NAME),
    selectQuerySortOrder(state, PARTS_QUEUE_TABLE_NAME),
  )

export const PartsQueueHeader = () => {
  const isSelected = useSelector((state: RootState) => selectIsChecked(state))

  return (
    <Box component="div">
      {isSelected ? (
        <GenericPartActionBar
          invalidateTable={() => {
            store.dispatch(invalidateChecked())
            store.dispatch(invalidateDetailsItem())
          }}
          selectNumChecked={selectNumChecked}
          selectParts={selectPartsQueueSelectedParts}
          isInspectorPanel={false}
          type={'parts'}
        />
      ) : (
        <Box
          component="div"
          sx={{
            margin: 'normal',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'center',
            width: '100%',
            gap: '6px',
          }}
        >
          <PartSearch table={PARTS_QUEUE_TABLE_NAME} />
        </Box>
      )}
    </Box>
  )
}

export const PARTS_QUEUE_TABLE_NAME = 'parts_queue_table_state'
export const PartsQueueTable = () => {
  const { t } = useTranslation('inbox')
  const selectPartIds = useMemo(() => {
    const emptyArray: TemplateReadPublic[] = []

    return createSelector(
      [(res?: PaginatedTemplateReadPublic) => res?.content ?? emptyArray],
      (content) =>
        content
          .filter((template) => template.parts_queued >= 1)
          .map((template) => template.id.toString()),

      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [])

  const data = useSelector((state: RootState) =>
    selectPartIds(
      selectRtkData(
        state,
        PARTS_QUEUE_TABLE_NAME,
        clientApi.endpoints.getTemplatesApiV1TemplatesGet.select,
      )?.data,
    ),
  )

  const totalCount = useSelector(
    (state: RootState) =>
      selectRtkData(
        state,
        PARTS_QUEUE_TABLE_NAME,
        clientApi.endpoints.getTemplatesApiV1TemplatesGet.select,
      )?.data?.total_count,
  )

  const partsColumns: Column[] = [
    { name: t('title.name'), key: 'name', sortable: true, hover: false },
    {
      name: t('title.partType'),
      key: 'classification',
      sortable: true,
      hover: false,
    },
    {
      name: t('title.received'),
      key: 'created_at',
      sortable: true,
      hover: false,
    },
    {
      name: t('title.status'),
      key: 'status',
      sortable: true,
      hover: false,
    },
  ]

  const handleSortTable = (sort?: Sort) => {
    if (sort !== undefined && sort.key !== undefined) {
      store.dispatch(
        setSortField({ name: PARTS_QUEUE_TABLE_NAME, value: sort.key }),
      )
      store.dispatch(
        setSortOrder({
          name: PARTS_QUEUE_TABLE_NAME,
          value: sort.order,
        }),
      )
    } else {
      store.dispatch(setSortField({ name: PARTS_QUEUE_TABLE_NAME, value: '' }))
      store.dispatch(
        setSortOrder({ name: PARTS_QUEUE_TABLE_NAME, value: 'asc' }),
      )
    }
  }

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        width: '100%',
        overflowX: 'auto',
        paddingBottom: '30px',
        boxSizing: 'border-box',
        '& tbody .entity-row': {
          backgroundColor: (theme) => `${theme.surface.high} !important`,
          '& .MuiTableCell-root': {
            padding: '0',
          },
        },
      }}
    >
      <DynamicTable
        stateName={PARTS_QUEUE_TABLE_NAME}
        columns={partsColumns}
        renderEntry={(entity) =>
          partsColumns.map((column, index) => (
            <EntityCell
              table={PARTS_QUEUE_TABLE_NAME}
              entity={entity}
              index={index}
            />
          ))
        }
        renderCollapsible={(visible, template, selected, index) => (
          <CollapsibleDetails
            visible={visible}
            template={template}
            index={index}
          />
        )}
        defaultCollapsedState
        checkable={false}
        menuOptions={[]}
        tableData={data}
        totalNumEntities={totalCount ?? 0}
        onSort={(sort) => {
          handleSortTable(sort)
        }}
        noMatch={false}
        mapId={(templates) => templates}
        hidePagination
      />
    </Box>
  )
}
